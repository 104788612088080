// 过滤器
// import store from '../vuex/store'
export default {
    // 登录保存用户信息
    saveUser: function (da) {
        console.log('ddd',da)
      if (da.code==='0000') {
        localStorage.setItem('userInfo',JSON.stringify(da.data))
      }
      return da
    },
    // 登出
    loginOut: function (da) {
      // 清理掉登录信息
        localStorage.clear()

      return da
    },
    // 登录状态
    loginStatus: function (da) {
        console.log('ddd',da)
      if ((da.code === '0000') && da.result) {
        console.log(da.result)
      } else {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('permissionCodeList')
        localStorage.removeItem('keyParams')
      }
      return da
    },
  }
