<template>
    <div class="home_container">
        <div class="left">
            <div
                class="button"
                :class="{
                    active: acitveMenu == 0,
                }"
                @click="changeMenu(0)"
            >
                <svg
                    width="20px"
                    height="20px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="design-iconfont"
                >
                    <path
                        d="M13.7142857,0 L2.28571429,0 C1.024,0 0,1.04524623 0,2.33313891 L0,9.33255562 C0,10.6204483 1.024,11.6656945 2.28571429,11.6656945 L4.568,11.6656945 L4.568,12.8579285 C4.568,13.2953921 4.808,13.688526 5.192,13.8821765 C5.35085714,13.9603366 5.52,14 5.688,14 C5.92914286,14 6.168,13.9195067 6.368,13.7631864 L9.03657143,11.6656945 L13.7142857,11.6656945 C14.976,11.6656945 16,10.6204483 16,9.33255562 L16,2.33313891 C16,1.04524623 14.976,0 13.7142857,0 Z M14.8571429,9.33255562 C14.8571429,9.97766853 14.3462857,10.4991251 13.7142857,10.4991251 L8.84685714,10.4979585 C8.72114286,10.4979585 8.59885714,10.539955 8.49942857,10.6181152 L5.70971429,12.8590951 L5.70971429,11.0812432 C5.70971429,10.7592701 5.45371429,10.4991251 5.13942857,10.4991251 L2.28571429,10.4991251 C1.65371429,10.4991251 1.14285714,9.97766853 1.14285714,9.33255562 L1.14285714,2.33313891 C1.14285714,1.688026 1.65371429,1.16656945 2.28571429,1.16656945 L13.7142857,1.16656945 C14.3462857,1.16656945 14.8571429,1.688026 14.8571429,2.33313891 L14.8571429,9.33255562 Z M4.57142857,4.66627781 C3.93942857,4.66627781 3.42857143,5.18773436 3.42857143,5.83284726 C3.42857143,6.47796017 3.93942857,6.99941672 4.57142857,6.99941672 C5.20342857,6.99941672 5.71428571,6.47796017 5.71428571,5.83284726 C5.71428571,5.18773436 5.20342857,4.66627781 4.57142857,4.66627781 Z M8,4.66627781 C7.368,4.66627781 6.85714286,5.18773436 6.85714286,5.83284726 C6.85714286,6.47796017 7.368,6.99941672 8,6.99941672 C8.632,6.99941672 9.14285714,6.47796017 9.14285714,5.83284726 C9.14285714,5.18773436 8.632,4.66627781 8,4.66627781 Z M11.4285714,4.66627781 C10.7965714,4.66627781 10.2857143,5.18773436 10.2857143,5.83284726 C10.2857143,6.47796017 10.7965714,6.99941672 11.4285714,6.99941672 C12.0605714,6.99941672 12.5714286,6.47796017 12.5714286,5.83284726 C12.5714286,5.18773436 12.0605714,4.66627781 11.4285714,4.66627781 Z"
                        transform="translate(2 3)"
                        fill="#FFF"
                        fill-rule="nonzero"
                    />
                </svg>
                <span>我的回复</span>
            </div>
            <div
                class="button"
                :class="{
                    active: acitveMenu == 1,
                }"
                @click="changeMenu(1)"
            >
                <svg
                    width="20px"
                    height="20px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    class="design-iconfont"
                >
                    <g fill-rule="nonzero" fill="none">
                        <path
                            d="M13.8098864,3.00096154 L10.8400568,0.176538462 C10.7209585,0.063443022 10.5607258,0 10.3938068,0 L0.636363636,0 C0.284971591,0 0,0.275576923 0,0.615384615 L0,15.3846154 C0,15.7244231 0.284971591,16 0.636363636,16 L13.3636364,16 C13.7150284,16 14,15.7244231 14,15.3846154 L14,3.43980769 C14,3.27480769 13.931392,3.11673077 13.8098864,3.00096154 Z M12.7272727,14.7692308 L1.27272727,14.7692308 L1.27272727,1.23076923 L8.90630682,1.23076923 L8.90849432,3.69346154 C8.90909091,4.37134615 9.47982955,4.92307692 10.1812216,4.92307692 L12.7272727,4.92307692 L12.7272727,14.7692308 Z M10.1812216,3.69230769 L10.179233,1.27442308 L12.7217045,3.69230769 L10.1812216,3.69230769 Z"
                            fill="#000"
                            transform="translate(3 2)"
                        />
                        <path
                            d="M3.18181818,6.15384615 L7,6.15384615 C7.35139205,6.15384615 7.63636364,5.87826923 7.63636364,5.53846154 C7.63636364,5.19865385 7.35139205,4.92307692 7,4.92307692 L3.18181818,4.92307692 C2.83042614,4.92307692 2.54545455,5.19865385 2.54545455,5.53846154 C2.54545455,5.87826923 2.83042614,6.15384615 3.18181818,6.15384615 Z M2.54545455,8.61538462 C2.54545455,8.95519231 2.83042614,9.23076923 3.18181818,9.23076923 L10.8181818,9.23076923 C11.1695739,9.23076923 11.4545455,8.95519231 11.4545455,8.61538462 C11.4545455,8.27557692 11.1695739,8 10.8181818,8 L3.18181818,8 C2.83042614,8 2.54545455,8.27557692 2.54545455,8.61538462 Z M10.8181818,11.0769231 L3.18181818,11.0769231 C2.83042614,11.0769231 2.54545455,11.3525 2.54545455,11.6923077 C2.54545455,12.0321154 2.83042614,12.3076923 3.18181818,12.3076923 L10.8181818,12.3076923 C11.1695739,12.3076923 11.4545455,12.0321154 11.4545455,11.6923077 C11.4545455,11.3525 11.1695739,11.0769231 10.8181818,11.0769231 Z"
                            fill="#000"
                            transform="translate(3 2)"
                        />
                    </g>
                </svg>
                <span>我的文章</span>
            </div>
        </div>
        <div class="main">
            <MyComments v-if="acitveMenu==0"></MyComments>
            <MyArticle v-else></MyArticle>
        </div>
        <div class="right">
            <div class="credits">
                <div class="title">社区积分</div>
                <div class="content">
                    <img width="44px" height="44px" src="../../assets/img/credits.png" alt="">
                    <span>
                        我的积分：{{userInfo.qudouNum}}分
                    </span>
                </div>
            </div>
<!--            <div class="more">-->
<!--                <img width="44px" height="44px" src="../../assets/img/more.png" alt="">-->
<!--                <span style="color: #999999;font-size: 14px;">-->
<!--                    暂无内容，敬请期待~-->
<!--                </span>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    import { getArticleList,getMyArticleReply } from '@/api/article.js';
    import MyArticle from './components/MyArticle.vue';
    import MyComments from './components/MyComments.vue';

    export default {
        name:'MyHome',
        components:{
            MyArticle,
            MyComments
        },
        data() {
            return {
                acitveMenu: 0,
                commentMe:[],
                userInfo:{},
            };
        },
        methods:{
            changeMenu(id){
                this.acitveMenu=id
            }
        },
        mounted:async function(){
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

        }
    };
</script>

<style lang="scss" scoped>
    .home_container {
        height: 87%;
      max-width: 1200px;
      min-width: 1170px;
      margin: 0 auto;
      display: flex;
      margin-bottom: 150px;
        gap: 15px;
        .main {
            flex: 1;
            background-color: #fff;
        }
        .left {
            width: 200px;
            background-color: #fff;
            padding: 20px 20px;
            .button {
                display: flex;
                align-items: center;
                padding: 20px;
                margin-top: 20px;
                height: 40px;
                border-radius: 4px;
                background: white;
                gap: 10px;
                cursor: pointer;
                span {
                    color: black;
                }
                path {
                    fill: #000000;
                }
            }
            .active {
                background: #165dff;
                span {
                    color: white;
                }
                path {
                    fill: #fff;
                }
            }
        }
        .right {
            width: 240px;
            .credits{
                background-color: #fff;
                height: 130px;
                border-radius: 4px;
                margin-bottom: 10px;
                .title{
                    box-sizing: content-box;
                    height: 30px;
                    line-height: 30px;
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px;
                    border-bottom: 1px solid #ECECEC;
                }
                .content{
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    gap:10px;
                    color:#333333;
                    font-size: 16px;
                }
            }
            .more{
                background-color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 200px;

            }
        }
    }
</style>
