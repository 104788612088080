<template>
  <div id="login">
    <div class="title-logo" @click="goHome"><img src="../../assets/shuangruilogo-sm.png" alt="">开发者社区</div>

    <div class="loginMain">

      <div class="content">
        <!-- 标题 -->
        <div class="title">{{"忘记密码" }}</div>
        <!-- 步进条 -->
        <div class="stepBerConter">
          <el-form :model="form" ref="form" :rules="rules" class="step-form">
            <el-form-item  prop="phone">
              <el-input v-model="form.phone" maxlength="11"  placeholder="请输入手机号">
                <i slot="prefix">
                  <img style="margin-top: 10px" src="../../assets/phone.png" alt />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item  prop="code" class="">
              <div class="verification-code">
                <el-input v-model="form.code" placeholder="请输入短信验证码" oninput="if(value.length>6)value=value.slice(0,6)"
                          auto-complete="new-password">
                  <i slot="prefix">
                    <img style="margin-top: 10px" src="../../assets/ver.png" alt /> </i></el-input>
                <el-button class="code" type="primary" v-if="timerTf"
                           @click="receiveShow ? receiveCode('form') : ''"><span class="code-span">获取验证码</span></el-button>
                <el-button class="button" v-if="!timerTf"><span class="code-span">{{ countDownNum }}秒</span></el-button>
              </div>
            </el-form-item>
            <el-form-item  prop="newPwd">
              <el-input type="Password" maxlength="20" v-model="form.newPwd" placeholder="请输入新密码"
                        auto-complete="new-password">
                <i slot="prefix">
                  <img style="margin-top: 10px" src="../../assets/password.png" alt />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item  prop="newPassword1">
              <el-input type="Password" maxlength="20" v-model="form.newPassword1" placeholder="请确认输入的新密码"
                        auto-complete="new-password">
                <i slot="prefix">
                  <img style="margin-top: 10px" src="../../assets/password.png" alt />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <div class="submit">

                <el-button type="primary" class="button" style="background: #165dff"
                           @click="isShow ? validateForm('form', 3) : ''">
                  <p>提交</p>
                </el-button>

                <el-button class="button" style="background: #165dff; color: #fff" @click="buttonClera">
                  <p>重新登录</p>
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import md5 from "js-md5";
    import axios from "axios";

    export default {
        name: "forgotPassword",
        components: {  },
        data() {
            var newPassword1 = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请再次输入密码"));
                } else if (value !== this.form.newPwd) {
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            };
            return {
                form: {
                    phone: "",
                    code: "",
                    newPwd: "",
                    newPassword1: "",
                },
                isShow: true,
                stepNum: 1,
                editLogin: '',
                second: 3,
                timerTf: true,
                countDownNum: 0,
                ismodify: false,
                receiveShow: true,
                rules: {
                phone: [{required: true, message: '手机号不能为空', trigger: ['blur']},
                    {pattern: /\s*\S+?/, message: '请输入非空字符', trigger: 'blur'},
                    {
                        pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                        message: '请输入正确的手机号',
                        trigger: 'blur'
                    }],
                    code: [{required: true, message: '验证码不能为空', trigger: ['blur']}, {
                pattern: /\s*\S+?/,
                message: '请输入非空字符',
                trigger: 'blur'
            },],
                    newPwd: [
                {pattern: /\s*\S+?/, message: '请输入非空字符', trigger: 'blur'},
                {required: true, message: '密码不能为空', trigger: ['blur']},
                // {pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,15}$/,message: '请输入8-20位密码，密码需包含数字、字母、特殊字符3种类型，字母区分大小写',trigger: 'blur'},],
              ],
                    newPassword1: [
                        { pattern: /\s*\S+?/, message: '请输入非空字符', trigger: 'blur' },
                        { required: true, message: "密码不能为空", trigger: ["blur"] },
                        { validator: newPassword1, trigger: "blur" },

                    ],
                },
            };
        },
        watch: {
            stepNum(val) {
                if (val == 3) {
                    let _this = this;
                    this.second = 3;
                    setInterval(() => {
                        _this.second--;
                        if (this.second == 0) {
                            this.$router.push("Login");
                        }
                    }, 1000);
                }
            },
        },
        computed: {},
        mounted() {
            this.gettingData();
        },
        methods: {

            goHome(){
                this.$router.push('/Home')
            },
            // 获取上个页面传来的数据
            gettingData() {
                this.form.phone = this.$route.query.username;
                this.ismodify = this.$route.query.loginFlag == 0 ? true : false;
                this.editLogin = this.$route.query.editLogin;
                this.form.phone = this.$route.query.editLogin ? JSON.parse(localStorage.getItem('userInfo')).phone : this.$route.query.username;
            },
            async receiveCode(form) {
                let _this = this;
                _this.receiveShow = false;
                _this.$refs[form].validateField("phone", async (valid) => {
                    setTimeout(function () {
                        _this.receiveShow = true;
                    }, 1000)
                    if (!valid) {
                        const res = await _this.API.user.verificationCode({
                            phone: _this.form.phone,
                        });
                        if (res ) {
                            this.$message.success('发送成功');
                            this.timerTf = false;
                            const TIME_COUNT = 60;
                            if (!this.timer) {
                                _this.countDownNum = TIME_COUNT;
                                _this.timerTf = false;
                                _this.timer = setInterval(() => {
                                    if (_this.countDownNum > 0 && _this.countDownNum <= TIME_COUNT) {
                                        _this.countDownNum--;
                                    } else {
                                        _this.timerTf = true;
                                        clearInterval(this.timer);
                                        this.timer = null;
                                    }
                                }, 1000);
                            }
                        } else {
                            this.$message({
                                message: res.msg,
                                type: "warning",
                            });
                            return;
                        }
                        // _this.loading = false;
                        // _this.timerTf = false;
                        // _this.countDownNum = 60;
                        // _this.timer = setInterval(() => {
                        //   _this.countDownNum--; receiveCode
                        //   if (_this.countDownNum <= -1) {
                        //     _this.timerTf = true;
                        //     clearInterval(_this.timer);
                        //   }
                        // }, 1000);
                    } else {
                        return false;
                    }
                });
            },
            buttonClera() {
                this.$router.go(-1);
            },
            validateForm(form, num) {
                let _this = this;
                _this.isShow = false;
                this.$refs[form].validate(async (valid) => {
                    if (valid) {
                            if (!this.form.code) {
                                this.$message({
                                    message: '请先获取验证码',
                                    type: "warning",
                                });
                                _this.isShow = true;
                                return
                            }
                            let params = {
                                ..._this.form,
                            };
                            delete params.newPassword1;
                            setTimeout(function () {
                                _this.isShow = true;
                            }, 1000)
                            const res = await _this.API.user.forgetPsw(params);
                            console.log(res)
                            if (res.code=='0000') {
                                this.$message({
                                    message: res.message ,
                                    type: "success",
                                });
                                setTimeout(function () {
                                    _this.$router.push("Login");
                                }, 1000)
                            } else {
                                this.$message({
                                    message: res.message,
                                    type: "warning",
                                });
                                return;
                            }
                    } else {
                        _this.isShow = true;
                        return false;
                    }
                });
            },
            step() {
                this.stepNum--;
            },
        },
    };
</script>
<style scoped lang="scss">
  .title-logo{
    display: flex;
    align-items: center;
    position: absolute;
    top: 40px;
    left: 80px;
    font-family: PingFangSC-Medium;
    font-weight: 600;
    font-size: 28.13px;
    color: #000000;
    cursor: pointer;
    img{
      width: 45px;
      margin-right: 8px;
    }
  }
  #login {
    width: 100%;
    height: 100%;
    position: fixed;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    .loginMain {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("../../assets/bg.png") no-repeat center;

      .img_logo {
        width: 280px;
        height: 53px;
        position: absolute;
        top: 2%;
        left: 3%;
      }
    }

    .content {
      background: #fff;
      width: 860px;
      height: 540px;
      opacity: 1;
      margin: 0 auto;
      padding: 70px 100px 0 100px;

      .title {
        font-family: PingFangSC-SNaNpxibold;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
      }

      .safetyReminder {
        //   width: 228px;
        height: 16px;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        text-align: center;
        color: #f53f3f;
        margin-top: 30px;
      }

      .stepBar {
        padding-left: 10px;
        margin-top: 70px;
      }

      .automatic-return {
        width: 300px;
        height: 60px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        :nth-child(1) {
          img {
            height: 100%;
          }
        }

        :nth-child(2) {
          display: flex;
          flex-direction: column;

          :nth-child(1) {
            height: 21px;
            font-family: MicrosoftYaHei;
            font-size: 16px;
            color: #333;
          }

          :nth-child(2) {
            margin-top: 20px;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #86909c;
          }
        }
      }
    }
  }

  .stepBerConter {
    width: 419px;
    margin: 40px auto;
  }
</style>
<style scoped lang="scss">
  .step-form {
    width: 419px;
    right: 0;

    .el-form-item {
      margin-bottom: 30px;

      .el-form-item__content {
        height: 41px;

        .el-input {
          input.el-input__inner {
            height: 48px;
            color: #999;
            // border: none;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 0;
            box-shadow: inset 0 0 0 48px #fff;
          }
        }
      }
    }

    .verification-code {
      display: flex;

      .el-input {
        img {
          margin-left: 5px !important;
        }
      }
    }

    .code {
      width: 150px;
      margin-left: 20px;
      background: #165dff;

      .code-span {
        color: #fff;
      }
    }

    .button {
      width: 150px;
      margin-left: 20px;
    }

    .submit {
      display: flex;
      justify-content: space-between;

      .button {
        width: 180px;
        height: 42px;

        p {
          color: #fff;
        }
      }

      .reLogin {
        width: 100%;
        margin-top: 60px;
        text-align: center;
      }
    }
  }
</style>
