<template>
  <div class="right-content">
    <div class="top-box">
      <div class="user-img">
        <img class="avatar" :src="userInfo.icon" alt="">
        <img class="icon" :src="levelList[userInfo.level-1]" alt="">
      </div>
      <div class="user-content">
        <div class="user-name-header">
          <span>{{userInfo.nickname}}</span>
          <img :src="levelSList[userInfo.level-1]" alt="">
        </div>
        <div class="user-name-btm">
          <div style="display: flex">
            <div class="item">
              <div class="num">{{userInfo.level}}</div>
              <div class="text">我的等级</div>
            </div>
            <div class="item">
              <div class="num">{{num}}</div>
              <div class="text">活跃度</div>
            </div>
          </div>
          <el-button type="primary" class="level-btn  " @click="levelUPUser">升级</el-button>
        </div>
      </div>
    </div>
    <div class="btm-box">
      <div class="title">
        <div class="line"></div>
        签到说明
      </div>
      <div class="level-icon">
        <img :src="levelBList[userInfo.level-1]" alt="">
        <div class="level-lv">当前等级：<span>LV{{userInfo.level}}</span></div>
      </div>
      <div class="step">
        <div class="step-item">
          <div class="num">0</div>
          <div class="lv">LV1</div>
        </div>
        <div class="step-item" style="margin-left: 52.5px;">
          <div class="num">300</div>
          <div class="lv">LV2</div>
        </div>
        <div class="step-item" style="margin-left: 124px">
          <div class="num">800</div>
          <div class="lv">LV3</div>
        </div>
        <div class="step-item" style="margin-left: 176px">
          <div class="num">1500</div>
          <div class="lv">LV4</div>
        </div>
        <div class="step-item" style="position: absolute; right: 0; text-align: right;">
          <div class="num">3000</div>
          <div class="lv">LV5</div>
        </div>
        <div class="line-bg">
          <el-tooltip class="item" effect="dark" :content="contentText" placement="right-start">
            <div class="already-line" :style="{width:lineWidth+'px'}"></div>
          </el-tooltip>
        </div>
      </div>
      <div class="tips">
        <img src="../../../assets/warning.png" alt="">
        不可利用程序增加活跃度，否则活跃度清零
      </div>
    </div>
  </div>
</template>

<script>
    import level1 from '../../../assets/level1.png'
    import level1Small from '../../../assets/level1-small.png'
    import level1Big from '../../../assets/level1-big.png'
    import level2Small from '../../../assets/level2-small.png'
    import level2Big from '../../../assets/level2-big.png'
    import level3Small from '../../../assets/level3-small.png'
    import level3Big from '../../../assets/level3-big.png'
    import level4Small from '../../../assets/level4-small.png'
    import level4Big from '../../../assets/level4-big.png'
    import level5Small from '../../../assets/level5-small.png'
    import level5Big from '../../../assets/level5-big.png'
    import level2 from '../../../assets/level2.png'
    import level3 from '../../../assets/level3.png'
    import level4 from '../../../assets/level4.png'
    import level5 from '../../../assets/level5.png'

    export default {
        name: "levelUp",
        data() {
            return {
                num:0,
                lineWidth:60,
                userInfo: {
                    icon:'../../../assets/user.jpg',
                    nickname:'',
                    level:0,
                },
                level1Small,
                level1Big,
                levelList: [
                    level1,
                    level2,
                    level3,
                    level4,
                    level5,
                ],
                levelSList: [
                    level1Small,
                    level2Small,
                    level3Small,
                    level4Small,
                    level5Small,
                ],
                levelBList: [
                    level1Big,
                    level2Big,
                    level3Big,
                    level4Big,
                    level5Big,
                ],
                contentText:'',
            }
        },
        mounted() {
            this.getInit()
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        },
        methods: {
            async getInit(){
                let res=await this.API.userSetting.getLevel()
                if(res.code=='0000'){
                    this.num=res.data
                    if(res.data>=3000){
                        this.lineWidth=3000*0.3
                    }else{
                        this.lineWidth=res.data*0.3
                    }
                    this.contentText='当前活跃度:'+res.data
                }else{
                    this.$message.error(res.message)
                }
            },
            async levelUPUser(){
                let res=await this.API.userSetting.levelUp()
                if(res.code=='0000'){
                    this.$message.success(res.message)
                    this.getInit()
                    this.userInfo.level++
                    localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
                }else{
                    this.$message.warning(res.message)
                }
            },
        },

    }
</script>

<style scoped lang="scss">
  .right-content {
    margin-left: 16px;
    width: 984px;

    .top-box {
      width: 100%;
      padding: 40px 40px 20px 40px;
      background: #FFFFFF;
      border-radius: 4px;
      display: flex;

      .user-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 20px;

        .avatar {
          width: 78px;
          height: 78px;
          border-radius: 50%;
        }

        .icon {
          width: 36px;
          height: 36px;
          margin-top: -18px;
        }
      }

      .user-content {
        width: 100%;

        .user-name-header {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ECECEC;
          padding-bottom: 8px;
          width: 100%;

          span {
            font-family: PingFangSC-SNaNpxibold;
            font-weight: 600;
            font-size: 16px;
            color: #333333;
          }

          img {
            width: 52px;
            height: 18px;
            margin-left: 8px;
          }
        }

        .user-name-btm {
          margin-top: 12px;
          display: flex;
          justify-content: space-between;

          .item {
            margin-right: 80px;

            .num {
              font-family: PingFangSC-SNaNpxibold;
              font-weight: 600;
              font-size: 24px;
              color: #333333;
              letter-spacing: 0;
            }

            .text {
              margin-top: 8px;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
            }
          }

          .level-btn {
            width: 148px;
            height: 44px;
            background: #165DFF;
            border-radius: 4px;
          }
        }
      }
    }

    .btm-box {
      width: 984px;
      padding: 28px;
      background: #FFFFFF;
      margin-top: 16px;
      border-radius: 4px;

      .title {
        display: flex;
        align-items: center;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 32px;

        .line {
          width: 4px;
          height: 16px;
          background: #165DFF;
          margin-right: 8px;
        }
      }

      .level-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 64px;
        img {
          margin-bottom: 20px;
        }

        .level-lv {
          font-family: PingFangSC-Medium;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          letter-spacing: 0;

          span {
            font-family: YouSheBiaoTiHei;
            font-size: 16px;
            color: #165DFF;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
          }

        }

      }
      .step{
        width: 900px;
        margin: 0 auto;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        position: relative;
        .line-bg{
          position: absolute;
          top: 20px;
          width: 900px;
          height: 8px;
          background: #ECECEC;
          border-radius: 11px;
          .already-line{
            width: 900px;
            height: 8px;
            background: #165DFF;
            border-radius: 11px;
          }
        }
        .step-item{
          .num{
            font-family: PingFangSC-SNaNpxibold;
            font-weight: 600;
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            margin-bottom: 24px;
          }
          .lv{
            font-family: PingFangSC-SNaNpxibold;
            font-weight: 600;
            font-size: 14px;
            color: #165DFF;
            letter-spacing: 0;
          }
        }
      }
      .tips{
        display: flex;
        margin-top: 58px;
        align-content: center;
        justify-content: center;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #FF5A5A;
        letter-spacing: 0;
        img{
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }

      }
    }
  }
</style>
