var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-content"},[_c('div',{staticClass:"top-box"},[_c('div',{staticClass:"user-img"},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.userInfo.icon,"alt":""}}),_c('img',{staticClass:"icon",attrs:{"src":_vm.levelList[_vm.userInfo.level-1],"alt":""}})]),_c('div',{staticClass:"user-content"},[_c('div',{staticClass:"user-name-header"},[_c('span',[_vm._v(_vm._s(_vm.userInfo.nickname))]),_c('img',{attrs:{"src":_vm.levelSList[_vm.userInfo.level-1],"alt":""}})]),_c('div',{staticClass:"user-name-btm"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.userInfo.level))]),_c('div',{staticClass:"text"},[_vm._v("我的等级")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.num))]),_c('div',{staticClass:"text"},[_vm._v("活跃度")])])]),_c('el-button',{staticClass:"level-btn",attrs:{"type":"primary"},on:{"click":_vm.levelUPUser}},[_vm._v("升级")])],1)])]),_c('div',{staticClass:"btm-box"},[_vm._m(0),_c('div',{staticClass:"level-icon"},[_c('img',{attrs:{"src":_vm.levelBList[_vm.userInfo.level-1],"alt":""}}),_c('div',{staticClass:"level-lv"},[_vm._v("当前等级："),_c('span',[_vm._v("LV"+_vm._s(_vm.userInfo.level))])])]),_c('div',{staticClass:"step"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"line-bg"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.contentText,"placement":"right-start"}},[_c('div',{staticClass:"already-line",style:({width:_vm.lineWidth+'px'})})])],1)]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"line"}),_vm._v(" 签到说明 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-item"},[_c('div',{staticClass:"num"},[_vm._v("0")]),_c('div',{staticClass:"lv"},[_vm._v("LV1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-item",staticStyle:{"margin-left":"52.5px"}},[_c('div',{staticClass:"num"},[_vm._v("300")]),_c('div',{staticClass:"lv"},[_vm._v("LV2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-item",staticStyle:{"margin-left":"124px"}},[_c('div',{staticClass:"num"},[_vm._v("800")]),_c('div',{staticClass:"lv"},[_vm._v("LV3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-item",staticStyle:{"margin-left":"176px"}},[_c('div',{staticClass:"num"},[_vm._v("1500")]),_c('div',{staticClass:"lv"},[_vm._v("LV4")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-item",staticStyle:{"position":"absolute","right":"0","text-align":"right"}},[_c('div',{staticClass:"num"},[_vm._v("3000")]),_c('div',{staticClass:"lv"},[_vm._v("LV5")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips"},[_c('img',{attrs:{"src":require("../../../assets/warning.png"),"alt":""}}),_vm._v(" 不可利用程序增加活跃度，否则活跃度清零 ")])
}]

export { render, staticRenderFns }