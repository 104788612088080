<template>

  <div class="article-content">
    <tableComponent
      :loading="listLoading"
      :tableData="list"
      :columns="columns"
      :total="total"
      :listQuery="params"
      :getList="getList"
      :handleList="handleList"
      @queryChange="tableComponentQueryChange"
    >
      <template slot="otherTemplate" slot-scope="{row}">
          <span v-if="row.username && row.username.length > 10">
         <el-popover
           placement="top"
           title=""
           trigger="hover"
           popper-class="workorder-remark"
         >
            <div>{{ row.username }}</div>
            <span slot="reference">
              {{ row.username.substr(0,10) + '...' }}
            </span>
         </el-popover>
       </span>
        <span v-else>{{ row.username }}</span>
      </template>
      <template slot="third" slot-scope="{row}">
          <span v-if="row.createIp && row.createIp.length > 8">
         <el-popover
           placement="top"
           title=""
           trigger="hover"
           popper-class="workorder-remark"
         >
            <div>{{ row.createIp }}</div>
            <span slot="reference">
              {{ row.createIp.substr(0,8) + '...' }}
            </span>
         </el-popover>
       </span>
        <span v-else>{{ row.createIp }}</span>
      </template>
      <template slot="template" slot-scope="{row}">
        <span class="opera-info" v-if="row.role!=1&&row.status==0" @click="handleLock(row)">冻结</span>
        <span class="opera-info" v-if="row.role!=1&&(row.status==1||row.status==2)" @click="handleUnLock(row)">解冻</span>
        <span class="opera-info" @click="handleDel(row)">删除</span>
        <span class="opera-info" v-if="row.role!=1" @click="handleSetRole(row)">设为管理员</span>

      </template>
    </tableComponent>
  </div>
</template>

<script>
    import TableComponent from '../../../components/TableComponent'

    export default {
        name: "userSetting",
        components: {TableComponent},
        data() {
            return {
                listLoading: false,
                list: [1, 2, 3],

                params: {
                    page: 1,
                    limit: 10,
                    textSearch: '',
                    sortProperty: 'createdTime',
                    sortOrder: 'DESC',
                },
                handleList: [],
                total: 0,
                columns: [{label: 'ID', prop: 'id'},
                    {label: '昵称', prop: 'nickname', width: 120},
                    {label: '用户账号', prop: 'username', otherTemplate: true , width: 120},
                    {label: '创建时间', prop: 'createTime', width: 140},
                    {label: '状态', prop: 'status',formatter:(val)=>{
                        return val==0?'使用中':val==1?'已删除':val==2?'冻结中':'-'
                        }},
                    {label: '角色', prop: 'role',formatter:(val)=>{
                        return val==1?'管理员':''
                        }},
                    {label: 'IP', prop: 'createIp',third:true,width:80},
                    {label: '操作', template: true, fixed: 'right', width: 200},],
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            async handleSetRole(row) {
                this.$confirm('确认授予当前用户为管理员吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let obj = {
                        role:1,
                        userId: row.id
                    }
                    let res = await this.API.userSetting.adminRole(obj)
                    if (res.code == '0000') {
                        this.$message.success('设置成功！')
                    } else {
                        this.$message.error(res.message)
                    }
                    this.getList()
                }).catch(() => {

                });

            },
            async handleDel(row) {
                this.$confirm('是否删除此用户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let obj = {
                        status:1,
                        userId: row.id
                    }
                    let res = await this.API.userSetting.changeStatus(obj)
                    if (res.code == '0000') {
                        this.$message.success('删除成功！')
                    } else {
                        this.$message.error(res.message)
                    }
                    this.getList()
                }).catch(() => {

                });

            },
            async handleLock(row) {
                this.$confirm('是否冻结此用户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let obj = {
                        status:2,
                        userId: row.id
                    }
                    let res = await this.API.userSetting.changeStatus(obj)
                    if (res.code == '0000') {
                        this.$message.success('冻结成功！')
                    } else {
                        this.$message.error(res.message)
                    }
                    this.getList()
                }).catch(() => {

                });

            },
            async handleUnLock(row) {
                this.$confirm('是否解冻此用户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let obj = {
                        status:0,
                        userId: row.id
                    }
                    let res = await this.API.userSetting.changeStatus(obj)
                    if (res.code == '0000') {
                        this.$message.success('解冻成功！')
                    } else {
                        this.$message.error(res.message)
                    }
                    this.getList()
                }).catch(() => {

                });

            },

            tableComponentQueryChange(query) {
                const {pageIndex, pageSize} = {...query}
                this.params.limit = pageSize
                this.params.page = pageIndex
            },
            async getList() {
                let obj = {
                    ...this.params
                }
                let res = await this.API.userSetting.userList(obj)
                if (res.code == '0000') {
                    this.list = res.data.list
                    this.total = res.data.total
                }
            },
            handleCreate() {
            },
        }

    }
</script>

<style scoped lang="scss">
  .article-content {
    min-height: 642px;
    width: 984px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-left: 16px;
    padding: 20px;
  }
</style>
