<template>

  <div class="article-content">
    <tableComponent
      :loading="listLoading"
      :tableData="list"
      :columns="columns"
      :total="total"
      :listQuery="params"
      :getList="getList"
      :handleList="handleList"
      @queryChange="tableComponentQueryChange"
    >
      <template slot="otherTemplate" slot-scope="{row}">
          <span v-if="row.articleTitle && row.articleTitle.length > 20">
         <el-popover
           placement="top"
           title=""
           trigger="hover"
           popper-class="workorder-remark"
         >
            <div>{{ row.articleTitle }}</div>
            <span slot="reference">
              {{ row.articleTitle.substr(0,20) + '...' }}
            </span>
         </el-popover>
       </span>
        <span v-else>{{ row.articleTitle }}</span>
      </template>
      <template slot="template" slot-scope="{row,$index}">
        <span class="opera-info" @click="handleExamine(row)">审核</span>
        <span class="opera-info" @click="articleNav(row.id)">查看</span>
        <span class="opera-info" @click="handleRecommend(row)">{{row.isRecommend==null||row.isRecommend=='0'?'推荐':row.isRecommend=='1'?'不推荐':'推荐'}}</span>
        <span class="opera-info" @click="handleDel(row)">删除</span>

      </template>
    </tableComponent>
  </div>
</template>

<script>
    import TableComponent from '../../../components/TableComponent'

    export default {
        name: "ArticleList",
        components: {TableComponent},
        data() {
            return {
                listLoading: false,
                list: [1, 2, 3],

                params: {
                    page: 1,
                    limit: 10,
                },
                handleList: [],
                total: 0,
                columns: [{label: 'ID', prop: 'id'},
                    {label: '标题', prop: 'articleTitle', width: 180, otherTemplate: true},
                    {label: '用户ID', prop: 'userId',},
                    {label: '创建时间', prop: 'createTime', width: 130},
                    {label: '标志', prop: 'delFlag',formatter:(val)=>{
                        return val=='-1'?'待审核':val=='0'?'审核通过':val=='1'?'已删除':'-'
                        },},
                    {label: '推荐', prop: 'isRecommend',formatter:(val)=>{
                            return val==null?'不推荐':val=='0'?'不推荐':val=='1'?'推荐':'-'
                        },},
                    {label: '操作', template: true, fixed: 'right', width: 200},],
            }
        },
        mounted() {
            this.getList()
        },
        methods: {

            articleNav(id) {
                this.$router.push(`/article/${id}`);

            },
            async handleExamine(row) {
                let obj = {
                    articleId: row.id
                }
                let res = await this.API.userSetting.examine(obj)
                if (res.code == '0000') {
                    this.$message.success('审核成功！')
                } else {
                    this.$message.error(res.message)
                }
                this.getList()
            },
            async handleDel(row) {
                this.$confirm('是否确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let obj = {
                        articleId: row.id
                    }
                    let res = await this.API.userSetting.articleDelete(obj)
                    if (res.code == '0000') {
                        this.$message.success('删除成功！')
                    } else {
                        this.$message.error(res.message)
                    }
                    this.getList()
                }).catch(() => {

                });

            },
            async handleRecommend(row) {
                let res = await this.API.userSetting.recommend({}, row.id)
                if (res.code == '0000') {
                    this.$message.success('推荐成功！')
                } else {
                    this.$message.error(res.message)
                }
                this.getList()
            },
            handleEdit() {
            },
            tableComponentQueryChange(query) {
                const {pageIndex, pageSize} = {...query}
                this.params.limit = pageSize
                this.params.page = pageIndex
            },
            async getList() {
                let obj = {
                    ...this.params
                }
                let res = await this.API.userSetting.adminManageList(obj)
                if (res.code == '0000') {
                    this.list = res.data.list
                    this.total = res.data.total
                }
            },
            handleCreate() {
            },
        }

    }
</script>

<style scoped lang="scss">
  .article-content {
    min-height: 642px;
    width: 984px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-left: 16px;
    padding: 20px;
  }
</style>
