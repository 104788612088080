<template>
  <div class="container">
    <div class="content_container">
      <div class="left">
        <div class="article_container">
          <div class="header">
            <div class="avatar">
              <img :src="article.anonymousSwitch==1?userIcon:articleUser.icon" alt="">
            </div>
            <div class="author">
              <div class="name">{{article.anonymousSwitch==1?'匿名用户':articleUser.nickname}}</div>
              <div class="time">{{articleUser.createTime}}</div>
            </div>
          </div>
          <div class='article-msg ql-editor' >
            <div class="article-title">{{ article.articleTitle }}</div>
            <div   v-html="article.articleContent"></div>
            <div class="btn-btm">
              <div class="like" @click="handleLikeArticle"><img src="../../assets/img/like.png" alt="">点赞
                &nbsp;&nbsp;{{article.likeCount==null?0:article.likeCount}}
              </div>
              <div class="msg"><img src="../../assets/img/pignlun.png" alt="">
                {{article.commentCount==null?0:article.commentCount}}条评论
              </div>
            </div>
          </div>
        </div>


        <div class="report-msg" v-if="userInfo">
          <img :src="userInfo.icon" alt="">
          <quill-editor
            class="editor-dtl"
            :toolbar="['underline', 'strike']"
            :options="editorOption"
            ref="myQuillEditor"
            v-model="commentContent"
          />
        </div>
        <div class="sub-msg" v-if="userInfo">
          <el-button  class="sub-btn" type="primary" @click="submitComment">提交评论</el-button>
        </div>
        <div class="comment_container">
          <div v-for="(comment,index) in comments" :key="index">
            <div class="user-info">
              <img :src="comment.postIcon" alt="">
              <div>
                <div class="name">{{comment.postNickname}}</div>
                <div class="time">{{comment.createTime}}</div>
              </div>
            </div>
            <div class="comment">
              <div class="msg">{{HTMLDecode(comment.content)}}</div>
              <div class="btn">
                <div class="item" @click="reportMsg(comment)" v-if="userInfo">
                  <img src="../../assets/img/huifu.png" alt="">
                  回复
                </div>
                <div class="item" @click="commentLike(comment)">
                  <img src="../../assets/img/dianzan_pinglun.png" alt="">
                 {{comment.likeCount}}
                </div>
                <div  class="item" v-if="userInfo&&article.userId==userInfo.id" @click="delReport(comment)">
                  <img src="../../assets/img/shanchu.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="right_box">
          <div class="header">作者简介</div>
          <template v-if="article.anonymousSwitch==1">
            <div>匿名用户不展示个人信息</div>
          </template>
          <template v-if="article.anonymousSwitch==0">
            <div class="box-item">
              <img src="../../assets/add.png" alt="">
              来自{{articleUser.city}}
            </div>
            <div class="box-item">
              <img src="../../assets/time.png" alt="">
              {{parseTime(articleUser.createTime)}}加入开发者社区
            </div>
            <div class="box-item">
              <img src="../../assets/sex.png" alt="">
              {{articleUser.sex==1?'是个男孩子':articleUser.sex==2?'是个女孩子':'性别未知'}}
            </div>
            <div class="box-item">
              <img src="../../assets/city.png" alt="">
              毕业于{{articleUser.school}}
            </div>
          </template>

        </div>
        <div class="right_box">
          <div class="header">推荐文章</div>
          <div class="article-item" v-for="(item,index) in recommandArticle" :key="index" @click="articleNav(item.id)">
            <div class="title">{{item.anonymousSwitch==1?'匿名用户': item.articleTitle }}</div>
            <div class="other">
              <span class="name">{{item.nickname}}</span>
              <span class="reader"><img src="../../assets/img/liulna.png" alt="">{{ item.browseCount }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import userIcon from '../../assets/user.jpg'
    import {quillEditor} from 'vue-quill-editor';
    import { parseTime } from '@/utils'

    import {
        commentArticle,
        getArticleList,
        getUserDetail,
        getArticle,
        getArticleComment,
    } from '@/api/article.js';

    export default {
        components: {
            quillEditor,
        },
        data() {
            return {
                userIcon,
                articleUser: {},
                userInfo: {},
                recommandArticle: [],
                article: {},
                comments: [],
                commentContent: '',
                toolBarOptions: [['bold', 'italic', 'underline', 'strike']],

                editorOption: {
                    placeholder: '请输入...',
                    modules: {
                        toolbar: [
                            [{header: [1, 2, false]}],
                            ['bold', 'italic', 'underline', 'strike'],
                            [{color: []}, {background: []}],
                            [{align: []}],
                            ['blockquote', 'code-block'],
                            [{list: 'ordered'}, {list: 'bullet'}],
                            ['image'],
                            ['clean'],
                        ],
                    },
                },
            };
        },
        methods: {
            parseTime,
            async delReport(val){
                this.$confirm('是否确认删除该评论?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let res=await this.API.article.commentDel({
                        commentId:val.id
                    })
                    if (res.code == '0000') {
                        this.$message.success('删除成功！')
                        this.article.commentCount--
                    } else {
                        this.$message.error(res.message)
                    }
                    this.getArticleCommentList()
                }).catch(() => {

                });
            },
            async handleLikeArticle(){
                let res=await this.API.article.articleLike({
                    articleId:this.article.id
                })
                if (res.code == '0000') {
                    this.$message.success(res.message)
                    this.getDtl()
                } else {
                    this.$message.error(res.message)
                }
            },
            async commentLike(val){
                let res=await this.API.article.commentLike({
                    commentId:val.id
                })
                if (res.code == '0000') {
                    this.$message.success(res.message)
                    this.getArticleCommentList()
                } else {
                    this.$message.error(res.message)
                }
            },
            reportMsg(val){
                console.log(val)
                this.commentContent+='@'+val.postNickname
            },
            HTMLDecode(text) {
                var output = '';
                if (text) {
                    var temp = document.createElement('div');
                    temp.innerHTML = text;
                    output = temp.innerText;
                }
                return output.replace(/<[^>]+>/g, '');
            },
            async submitComment() {
                console.log(this.commentContent.length)
                if (this.commentContent&&this.commentContent.length<=140) {
                    const URLformData = new URLSearchParams()
                    URLformData.append('postUserId', this.userInfo.id)
                    URLformData.append('topicId', this.article.id)
                    URLformData.append('content', this.commentContent)
                   const res= await commentArticle(URLformData)
                    if (res.data.code == '0000') {
                        this.$message.success(res.data.message)
                        this.getArticleCommentList()
                        this.article.commentCount++
                    } else {
                        this.$message.error(res.data.message)
                    }
                this.commentContent=''
                } else {
                    this.$message.warning('评论不能为空或评论超出规定最大字符')
                    return
                }
            },
            async getArticleCommentList(){
                const authRes = await getArticleComment({
                        articleId: this.article.id,
                    })
                if(authRes.data.code=='0000'){
                    this.comments = authRes.data.data;
                }else{
                    this.$message.error(authRes.data.message)
                }
            },
            async getDtl(){

              const res= await getArticle({
                    articleId: this.$route.params.id,
                })
                if(res.data.code=='0000'){
                    this.article = res.data.data;
                }else{
                    this.$message.error(res.data.message)
                }
            },
            articleNav(id) {
                this.$router.push(`/article/${id}`);
                location.reload();

            },
        },
        mounted: async function () {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

            const res = await Promise.all([
                getArticleList({
                    isRecommend: 1,
                    page: 1,
                    regulation: 0,
                }),
                getArticle({
                    articleId: this.$route.params.id,
                }),
            ]);
            this.recommandArticle = res[0].data.data.list;
            this.article = res[1].data.data;
            const authRes = await Promise.all([
                getUserDetail({
                    userId: this.article.userId,
                }),
                getArticleComment({
                    articleId: this.article.id,
                }),
            ]);
            this.articleUser = authRes[0].data.data;
            this.comments = authRes[1].data.data;
        },
    };
</script>

<style lang="scss" scoped>
  .container {
    max-width: 1200px;
    min-width: 1175px;
    margin: 0 auto;

    .content_container {
      color: #333;
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-top: 15px;
      .left {
        width: calc( 100% - 300px);

        height: 87vh;
        overflow: auto;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 4px;
        margin: 0 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        .article-content {
          width: 100%;
          height: 100%;
        }

        .header {
          display: flex;
          align-items: center;

          .avatar {
            img {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              margin-right: 20px;
            }
          }

          .author {
            .name {
              margin-bottom: 6px;
              font-family: PingFangSC-Medium;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
            }

            .time {
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 12px;
              color: #666666;
              letter-spacing: 0;
              line-height: 16px;
            }
          }
        }
      }

      .article-msg {
        margin-top: 20px;

        .article-title {

          font-family: PingFangSC-SNaNpxibold;
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          letter-spacing: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-bottom: 20px;
        }

        .btn-btm {
          margin-top: 20px;
          display: flex;
          margin-bottom: 20px;

          .like {
            width: 112px;
            height: 30px;
            background: #165dff1a;
            border-radius: 4px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 14px;
            color: #165DFF;
            letter-spacing: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }

          .msg {
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            letter-spacing: 0;

            img {

              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
        }
      }

      .report-msg {
        position: relative;

        img {
          position: absolute;
          width: 45px;
          height: 45px;
          margin-right: 12px;
          border-radius: 50%;
        }

        .editor-dtl {
          margin-left: 10%;
          width: 90%;
        }
      }
      .sub-msg{
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
        .sub-btn {
          height: 36px;
          background: #165DFF;
          border-radius: 4px;
        }
      }
      .comment_container{
        margin-top: 20px;
        .user-info{
          display: flex;
          img{
            width: 45px;
            height: 45px;
            border-radius: 50%;
            margin-right: 12px;
          }
          .name{

            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;
            margin-bottom: 6px;
          }
          .time{

            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            letter-spacing: 0;
            line-height: 16px;
          }

        }
        .comment{
          margin-top: 12px;
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .msg{
            width: 80%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
          }
          .btn{
            display: flex;
            align-items: center;
            .item{
              display: flex;
              align-items: center;
              margin-right: 12px;
              cursor: pointer;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 14px;
              color: #999999;
              letter-spacing: 0;
            }
          }
        }
      }
      .right {
        width: 300px;

        .right_box {
          width: 100%;
          background-color: #fff;
          margin-bottom: 10px;
          padding: 20px;
          .header {
            padding-bottom: 17px;
            margin-bottom: 10px;
            border-bottom: 1px dotted #e9e9e9;

            font-family: PingFangSC-Medium;
            font-weight:600;
            font-size: 20px;
            color: #333333;
            letter-spacing: 0;
          }
            .box-item{
              display: flex;
              align-content: center;
              line-height: 24px;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 14px;
              color: #333333;
              letter-spacing: 0;
              margin-bottom: 16px;
              img{
                width: 20px;
                height: 20px;
                margin-right: 6px;
              }
            }
          .article-item{

            margin-bottom: 16px;
            .title{
              font-family: PingFangSC-Medium;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              margin-bottom: 12px;
            }
            .other{
              display: flex;
              justify-content: space-between;
              align-items: center;
              .name{

                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                letter-spacing: 0;
                line-height: 16px;
              }
              .reader{
                img{
                  width: 12px;
                  margin-right: 8px;
                }
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                letter-spacing: 0;
                line-height: 16px;
              }
            }

          }
          .tags {
            padding: 25px 20px;

            span {
              color: white;
              padding: 3px 5px;
              background-color: #ffb800 !important;
              border-radius: 2px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>
