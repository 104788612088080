<template>
  <div class="content">
    <div class="item" style="width: 60%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="城市" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="item Sign-in" style="width: 40%">已有账号？<span @click="login">直接登录</span></div>
  </div>
</template>

<script>
    export default {
        name: "signIn",
        data() {
            return {
                ruleForm: {
                    email: '',
                    password: '',
                    rememberMe: '',

                },
                rules: {
                    email: [
                        {required: true, message: '不能为空', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '不能为空', trigger: 'change'}
                    ],
                    rememberMe: [
                        { required: true, message: '不能为空', trigger: 'change'}
                    ],

                }
            }
        },
        mounted(){},
        methods:{
            login(){
                this.$router.push('/Login')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        alert('submit!');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        },
    }
</script>

<style scoped lang="scss">
  .content {
    width: 1170px;
    background: white;
    margin: 0 auto;
    display: flex;
    padding: 24px;
    justify-content: space-between;
    .item{
      display: flex;
      span{
        color: #409EFF;
        cursor: pointer;
      }
    }
    .el-button--text /deep/{
      span{
        color: gray !important;
      }
    }
  }
  .Sign-in{
    margin-top: 24px;
  }
  .demo-ruleForm{
    width: 70%;
  }
</style>
