<template>
  <div class="table-container">
    <!-- <div class="table-small-container"> -->
      <div class="total-data" v-if="isShowTotalData">
        <slot name="totalData">
        </slot>
      </div>
      <div v-if="showTableCtrl" class="table-ctrl">
  <!--      <div class="table-title">{{tableTitle}}</div>-->
        <div class="ctrl-box">
          <el-button
            v-for="(btn,index) in handleList"
            :key="index"
            style="margin-left: 10px;"
            :type="btn.type"
            :icon="btn.icon"
            @click="btn.fn()"
          >
            {{ btn.label }}
          </el-button>
          <el-popover
            v-if="handleList_hh.length>0"
            placement="bottom"
            v-model="popVisible">
            <div style="display: flex; flex-direction: column">
              <el-button
                v-for="(btn,index) in handleList_hh"
                :key="index"
                style="margin-left: 0;margin-bottom: 8px"
                :type="btn.type"
                :icon="btn.icon"
                @click="()=>{btn.fn();popVisible=false}"
              >
                {{ btn.label }}
              </el-button>
            </div>
            <el-button slot="reference" type="primary" icon="el-icon-plus">新增数据</el-button>
          </el-popover>
          <el-upload
          style="display: inline; margin-left: 10px;"
          v-for="(btn, i) in uploadXLSList"
          :key="'xls'+ i"
          :action="btn.action"
          :show-file-list="false"
          :http-request="btn.uploadAction"
          :accept="btn.accept"
          >
            <el-button class="filter-item" type="primary" :icon="btn.icon">{{btn.label}}</el-button>
          </el-upload>
          <div class="table-settings" v-if="showSetting">
            <el-tooltip :hide-after="500" class="item" effect="dark" content="刷新" placement="top-start">

              <i class="el-icon-refresh"  @click="refresh"></i>
            </el-tooltip>
            <el-tooltip :hide-after="500" class="drop-item" effect="dark" content="行高" placement="top-start">
              <el-dropdown @command="handleClick">
                <span>
                  <i class="el-icon-s-unfold"></i>
                </span>
                <el-dropdown-menu slot="dropdown"  >
                  <el-dropdown-item command="默认" :class="{dropActive:lineHeight=='默认'}">默认</el-dropdown-item>
                  <el-dropdown-item command="中等" :class="{dropActive:lineHeight=='中等'}">中等</el-dropdown-item>
                  <el-dropdown-item command="紧凑" :class="{dropActive:lineHeight=='紧凑'}">紧凑</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-tooltip>
            <el-tooltip :hide-after="500" class="drop-item" effect="dark" content="列设置" placement="top-start">

              <el-popover
                width="400"
                trigger="click"
                @show="getTemplate"
              >
                <div class="checkbox">
                  <el-checkbox-group v-model="checkList" @change="handleCheckChange">
                    <el-checkbox label="列展示" :indeterminate="isIndeterminate"></el-checkbox>
                    <el-checkbox label="序号列"></el-checkbox>
                    <el-checkbox label="勾选列"></el-checkbox>
                    <el-button type="text" @click="resetCheck">重置</el-button>
                  </el-checkbox-group>
                </div>
                <div class="setting-box">
                  <el-checkbox-group v-model="templateColumns" @change="handleCheckColumn">
                    <draggable class="dragArea" v-model="tableColumns" animation="300" handle=".rank-ctrl" @update="dragUpdate">
                      <div class="setting-item" v-for="(item,index) in tempColumns" :key="index" >
                          <span class="rank-ctrl el-icon-rank"></span>
                          <div class="check-item">
                            <el-checkbox :label="item.label"></el-checkbox>
                          </div>
                          <el-tooltip :hide-after="500" class="drop-item" effect="dark" content="固定到左侧" placement="top-start">
                            <div class="fixed-ctrl" :class="{isFixed:item.fixed=='left'||item.fixed==true}" @click="fixedLeft(index)"><i class="el-icon-back"></i></div>
                          </el-tooltip>
                          <el-tooltip :hide-after="500" class="drop-item" effect="dark" content="固定到右侧" placement="top-start">
                            <div class="fixed-ctrl" :class="{isFixed:item.fixed=='right'}" @click="fixedRight(index)"><i class="el-icon-right"></i></div>
                          </el-tooltip>
                      </div>
                    </draggable>
                  </el-checkbox-group>

                </div>
                <span slot="reference">
                  <i class="el-icon-setting"></i>
                </span>
              </el-popover>
            </el-tooltip>
          </div>
        </div>
      </div>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        fit
        highlight-current-row
        style="width: 100%;"
        ref="table"
        :size="lineHeight=='默认'?'medium':lineHeight=='中等'?'small':'mini'"
        :row-key="rowKey"
        :tree-props="treeProps"
        :max-height="maxHeight"
        @selection-change="handleSelectionChange"
      >
        <el-table-column v-if="selection" type="selection" width="55"></el-table-column>

        <el-table-column
          :prop="col.prop"
          :label="col.label"
          v-for="(col, coli) in tableColumns"
          :key="coli"
          :formatter="col.formatter"
          :min-width="col.width|calcWidth(col)"
          :fixed="col.fixed"
          :sortable="sort"
          :show-overflow-tooltip="col.showOverflowTooltip"
          :lazy="lazy"
          :load="loadLazy"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="col.template" >
              <slot name="template" v-bind="scope" />
            </template>
            <template v-else-if="col.otherTemplate" >
              <slot name="otherTemplate" v-bind="scope" />
            </template>
            <template v-else-if="col.third" >
              <slot name="third" v-bind="scope" />
            </template>
            <el-checkbox v-else-if="col.isCheckbox" disabled :checked="scope.row[col.prop]"></el-checkbox>
            <el-tag v-else-if="col.isTag" :type="getTagType(scope.row[col.prop], col.successVal, col.falseType)">{{getTagTxt(scope.row[col.prop], col.successVal, col.txts)}}</el-tag>
            <template v-else-if="col.isImage">
              <el-image v-if="scope.row[col.prop]" :src="scope.row[col.prop]" style="height: 25px;" :preview-src-list="[scope.row[col.prop]]"></el-image>
              <span v-else></span>
            </template>
            <span v-else-if="col.formatter">{{col.formatter(scope.row[col.prop], col.prop,scope.row)|calcValue(col.default)}}</span>
            <span v-else>{{scope.row[col.prop]|calcValue(col.default)}}</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="query.pageIndex"
        :limit="query.pageSize"
        :layout="paginationLayout"
        @pagination="queryChange"
      />
    <!-- </div> -->
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination' // secondary package based on el-pagination
  import draggable from 'vuedraggable'
  import { deepClone } from '@/utils';
  import axios from 'axios'

  export default {
    name: 'VTable',
    components: {Pagination,draggable},
    props: {
      tableTitle:{
        type:String
      },
      isShowTotalData:{
        type:Boolean,
        default:false,
      },
      loading: {
        type: Boolean,
        default: true
      },
      tableData: {
        type: Array,
        default:  () => {
          return []
        }
      },
      order:{
        type:Boolean,
        default:true,
      },
      isSelection:{
        type:Boolean,
        default:false
      },
      columns:{
        type:Array,
        default: () => {
          return []
        }
      },
      sort: {
        type: Boolean,
        default:false
      },
      total:{
        default:0
      },
      listQuery:{
        type:Object,
        default: () => {
          return {
            pageIndex: 1,
            pageSize: 10,
          }
        }
      },
      getList:{
        type:Function,
      },
      handleList_hh:{
        type:Array,
        default: ()=> {
          return []
        }
      },
      handleList:{
        type:Array,
      },
      uploadXLSList: {
        type: Array,
        default: ()=> {
          return []
        }
      },
      rowKey: {
        type: String,
        default: ''
      },
      treeProps: {
        type: Object,
        default: () => {
          return {}
        }
      },
      showTableCtrl: {
        type: Boolean,
        default: true
      },
      maxHeight: {
        type: String,
        default: '-'
      },
      lazy: {
        type: Boolean,
        default: false
      },
      showSetting: {
        type: Boolean,
        default: true
      },
      paginationLayout: {
        type: String,
        default: 'total, sizes, prev, pager, next, jumper'
      }
    },
    data(){
      return{
        popVisible:false,
        lineHeight:'默认',
        checkList:['列展示'],
        templateColumns:[],
        temArray:[],
        tableColumns:deepClone(this.columns),
        selection:this.isSelection,
        isOrder:this.order,
        query: {pageIndex: 1, pageSize: 10},
        tempColumns:[],
      }
    },
    mounted(){
      console.log()
      this.tempColumns=this.columns;
    },
    computed:{
      isIndeterminate(){
        if(this.templateColumns.length==this.columns.length){
          return false;
        }else{
          return true
        }
      },
    },
    watch:{
      checkList:{
        handler(newValue,oldValue){
          if(newValue.some(val => ['列展示'].includes(val))){
            this.templateColumns = []
            this.tableColumns.forEach(ele=>{
              this.templateColumns.push(ele.label)
            })
          }else{
            this.templateColumns=[];
          }
        },
        // immediate:true
      },
      listQuery: {
        handler(val) {
          const {page, pageIndex, pageSize, current, size} = { ...val }
          // console.log(this.query)
          this.query.pageIndex = pageIndex || page || current
          this.query.pageSize = pageSize || size
          // console.log(this.query)
        },
        deep: true
      },
    },
    methods:{
      refresh(){
        this.getList();
      },
      getTemplate(){
        // this.resetCheck();
        this.templateColumns = []
        this.tableColumns.forEach(ele=>{
          this.templateColumns.push(ele.label)
        })
        // this.temArray = this.templateColumns;
      },
      handleClick(command){
        this.lineHeight=command;
      },
      handleCheckChange(val){

        if(val.some(v => ['勾选列'].includes(v))){
          this.selection = true;
        }else{
          this.selection = false;
        }
        if(val.some(v => ['序号列'].includes(v))){
          this.isOrder = true;
        }else{
          this.isOrder = false;
        }
      },
      handleCheckColumn(val){
        if(val && val.length==0){
          this.checkList.splice(this.checkList.indexOf('列展示'),1);
        }
        let columns = [];
        this.columns.forEach(ele=>{
          if(this.templateColumns.some(item=>[ele.label].includes(item))){
            columns.push(ele)
          }
        })
        this.tableColumns =columns
      },
      dragUpdate(){
        let arr = [...this.tableColumns,...this.tempColumns];
        let stringArr = arr.map(item => JSON.stringify(item))
        let newArr = Array.from(new Set(stringArr))
        let objArr = newArr.map(item => JSON.parse(item))
        this.tempColumns = objArr;
      },
      fixedLeft(index){
        if(this.tableColumns[index].fixed){
          if(this.tableColumns[index].fixed=='left'){
            this.tableColumns[index].fixed = null;
          }else{
            this.tableColumns[index].fixed = 'left';
          }
        }else{
          this.$set(this.tableColumns[index],'fixed','left')
        }
      },
      fixedRight(index){
        if(this.tableColumns[index].fixed){
          if(this.tableColumns[index].fixed=='right'){
            this.tableColumns[index].fixed = null;
          }else{
            this.tableColumns[index].fixed = 'right';
          }

        }else{
          this.$set(this.tableColumns[index],'fixed','right')
        }
      },
      resetCheck(){
        this.checkList=['列展示']
        if(this.order){
          this.checkList.push('序号列')
        }
        if(this.isSelection){
          this.checkList.push('勾选列')
        }
        if(this.checkList.some(v => ['勾选列'].includes(v))){
          this.selection = true;
        }else{
          this.selection = false;
        }
        this.tableColumns = deepClone(this.columns);
      },
      getTagType(val, successVal=true, falseType=null) {
        return val == successVal? 'success': falseType? falseType: 'info'
      },
      getTagTxt(val, successVal, txts={success: '在线', info: '离线'}) {
        return val == successVal? txts.success: txts.info
      },
      //上传结果
      // handleUpload(res, file, fileList) {
      //   if (res.state == 'success') {
      //     this.$message({ type: "success", message: "文件上传成功" });
      //     if (this.getList) {
      //       this.getList()
      //     }
      //   } else {
      //     this.$message({
      //       type: "warning",
      //       message: res.msg,
      //       duration: 60000,
      //       dangerouslyUseHTMLString: true,
      //       showClose: true
      //     });
      //   }
      // },
      // 导入实现
      uploadAction(e) {
        let fd = new FormData(); //参数的格式是formData格式的
        fd.append("file", e.file); //参数
        const service = axios.create({
          timeout: 60000,
          responseType: 'blob',
          // withCredentials: true, // 是否允许带cookie这些
          baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
          headers: {
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary9Y6udNDeSVsokdDf',
            'X-Authorization': 'Bearer ' + this.$store.getters.token
            // 'X-Authorization': 'Bearer ' + getToken()
          }
        })
        let type = '0'
        if (e.action.indexOf('type=') > 0) {
          const urls = e.action.split('type=')
          type = urls[1]
        }
        const types = {'1': '机构', '2': '物模型', '3': '设备'}
        const txt = types[type] || ''
        service({url: e.action, method: 'post', data: fd}).then((res) => {
          // console.log(res)
          if (res.status == 200) {
            this.$message({ type: "success", message: "接口调用完成，请查看上传结果文件" });
            this.getList()
            let a = document.createElement('a');
            //ArrayBuffer 转为 Blob
            let blob = new Blob([res.data], {type: "application/"+ res.data.type});

            let objectUrl = URL.createObjectURL(blob);
            a.setAttribute("href",objectUrl);
            a.setAttribute("download", txt+ '上传结果文件.txt');
            a.click();
          }
        });
      },
      queryChange(e) {
        this.$emit('queryChange', {pageIndex: e.page, pageSize: e.limit})
        this.getList()
      },
      handleSelectionChange(val) {
        this.$emit('handleSelectionChange', val)
      },
      loadLazy(tree, treeNode, resolve) {
        // const parentId = tree.id
        // if (this.loadLazyFun.length >= 2) {
        //   const res = this.API[this.loadLazyFun[0]][this.loadLazyFun[1]]({parentId})
        //   resolve(res.data)
        // }
        this.$emit('loadLazy', {tree, treeNode, resolve})
      }
    },
    filters:{
      calcValue(value, defaultValue) {
        defaultValue = defaultValue === void 0 ? "-" : defaultValue;
        // if (value instanceof Object) value = value.label; // 防止传入对象
        return value === undefined || value === null || value === ""
          ? defaultValue
          : value;
      },
      calcWidth(width, col) {
        const label = col.label;
        if (width) {
          return width;
        } else {
          if (label.indexOf("date") > -1||label.indexOf("time") > -1||label.indexOf("Time") > -1||label.indexOf("Date") > -1) {
            return "160";
          } else if (label.indexOf("Name") > -1||label.indexOf("name") > -1) {
            return "120";
          } else {
            const length = label.length;
            const doubleCount =
              label.match(/[^\x00-\xff]/gi) &&
              label.match(/[^\x00-\xff]/gi).length;
            const singleCount = length - doubleCount;
            const width = doubleCount * 14 + singleCount * 7 + 40;
            return width + (col.tooltip ? 10 : 0);
          }
        }
      },
    },


  }
</script>

<style scoped lang="scss">


html[data-theme='dark'] {
  // .table-container{
  //   background: #1C242E;
  // }
  ::v-deep{
    .el-icon-arrow-right {
      color: #c9d1d9;
    }
    .el-dropdown-menu__item.dropActive{
      color:#0e5ba3;
      background-color:#e8f4ff;
    }
  }
}
  .table-title{
    border-left: 2px solid #4dd9d5;
    padding-left: 8px;
  }
  .table-container{
    // background: #1C242E;
    // .table-small-container{
    //   padding: 16px;
    //   border-radius: 12px;
      .table-ctrl{
        // height: 40px;
        margin-left: -10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .ctrl-box{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          .table-settings{
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            .item{
              cursor: pointer;
              width: 24px;
              height: 24px;
              padding: 4px;
            }
          }
          .drop-item{
            padding: 4px;
            cursor: pointer;
            svg{
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    // }
  }
  .checkbox{
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom:1px solid #f0f0f0;
  }
  .setting-box{
    .setting-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height:30px;
      .check-item{
        width:70%;
      }
      .rank-ctrl,.fixed-ctrl{
        width: 20px;
        height: 20px;
        font-size: 14px;
        cursor: pointer;
        &.isFixed{
          svg{
            fill:#1890ff
          }
        }
      }
      .rank-ctrl{
        cursor: move;
        font-size: 18px;
      }
    }
  }
  .total-data{
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    margin: 0 auto;
    background: rgba(255,255,255,0.07);
    box-shadow: 0 10px 60px -5px rgba(0,0,0,0.05);
    border-radius: 4px;
    margin-bottom: 24px;
  }


</style>
