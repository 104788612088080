<template>
  <div class="content">
    <div class="menu-content">
      <div class="menu-item"  :class="activeUrl==item.url?'menu-item-active':''" @click="handleChange(item)" v-for="(item,index) in menuList" :key="index">
        <img :src="activeUrl==item.url?item.activeIcon:item.icon" alt="">{{item.name}}
      </div>
      <div class="menu-item" v-if='userInfo.role==1'  :class="activeUrl==item.url?'menu-item-active':''" @click="handleChange(item)" v-for="(item,index) in menuRoleList" :key="index+2">
        <img :src="activeUrl==item.url?item.activeIcon:item.icon" alt="">{{item.name}}
      </div>
    </div>
    <level-up v-if="activeUrl=='levelUp'"></level-up>
    <setting v-if="activeUrl=='setting'"></setting>
    <ArticleList v-if="activeUrl=='article'"></ArticleList>
    <user-setting v-if="activeUrl=='userSetting'"></user-setting>
  </div>
</template>
<script>
    import  setting from '../../assets/setting.png'
    import settingActive from '../../assets/setting-active.png'
    import levelUpPng from '../../assets/levelUp.png'
    import levelUpActive from '../../assets/levelUp-active.png'
    import article from '../../assets/article.png'
    import articleActive from '../../assets/article-active.png'
    import userSetting from '../../assets/userSetting.png'
    import userSettingActive from '../../assets/user-set-active.png'
    import LevelUp from "./component/levelUp";
    import Setting from "./component/setting";
    import ArticleList from "./component/articleList";
    import UserSetting from "./component/userSetting";

    export default {
        name: "index",
        components: {UserSetting, ArticleList, Setting, LevelUp},
        data() {
            return {
                userInfo:{
                    role:null,
                },
                activeUrl:'levelUp',
                menuRoleList:[
                    {
                        id: 3,
                        name: '文章管理',
                        icon: article,
                        activeIcon: articleActive,
                        url: 'article',
                    },
                    {
                        id: 4,
                        name: '用户管理',
                        icon: userSetting,
                        activeIcon: userSettingActive,
                        url: 'userSetting',
                    },],
                menuList: [
                    {
                        id: 1,
                        name: '升级之路',
                        icon: levelUpPng,
                        activeIcon: levelUpActive,
                        url: 'levelUp',
                    },
                    {
                        id: 2,
                        name: '基本设置',
                        icon: setting,
                        activeIcon: settingActive,
                        url: 'setting',
                    },
                ],
            }
        },
        mounted() {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        },
        methods:{
            handleChange(item){
                this.activeUrl=item.url
            },
        },
    }
</script>

<style scoped lang="scss">
  .content {
    max-width: 1200px;
    min-width: 1170px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 150px;
  }

  .menu-content {
    width: 200px;
    color: #fff;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 30px 20px;

    .menu-item {
      line-height: 36px;
      margin-bottom: 12px;
      display: flex;
      cursor: pointer;
      align-items: center;
      width: 100%;
      padding: 0 20px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      img{
       width: 22px;
        height: 22px;
        margin-right: 12px;
      }
    }
    .menu-item:hover,.menu-item-active{
      background: #165DFF;
      border-radius: 4px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
</style>
