import client from './index.js';

export const getArticleList = (params) => {
    return client.get('/api/article/manage/list', {
        params: params,
    });
};

export const getArticle = (params) => {
    return client.get(`/api/article/getByArticleId`,{
        params
    });
};

export const getArticleComment = (params) => {
    return client.get(`/api/article/commentList`, {
        params,
    });
};

export const getUserDetail = (params) => {
    return client.get('/api/u/detail', {
        params: params,
    });
};

export const getTagsList = (params) => {
    return client.get('/api/lable/list', {
        params: params,
    });
};

export const likeArticle = (params) => {
    return client.post('/api/article/like', {
        params: params,
    });
};

export const writeArticle = (data) => {
    return client.post('/api/article/writeArticle', data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    });
};

export const commentArticle = (data) => {
    return client.post('/api/article/comment',data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    });
};

export const getMyArticleReply = (params) => {
    return client.get('/api/comment/myArticleReply', {
        params: params,
    });
};
