<template>
  <div class="setting-content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的资料" name="1">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="手机" prop="username">
            <el-input v-model="ruleForm.username" disabled style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="nickname">
            <el-input v-model="ruleForm.nickname" style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="城市" prop="city">
            <el-input v-model="ruleForm.city" style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="生日" prop="birth">
            <el-date-picker type="date" style="width: 400px;"
                            placeholder="请选择" v-model="ruleForm.birth"
                            value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>

          <el-form-item label="学历" prop="education">
            <el-select v-model="ruleForm.education" style="width: 400px;" placeholder="请选择">
              <el-option v-for="(item,index) in eduList" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="学校" prop="school">
            <el-input v-model="ruleForm.school" style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="ruleForm.sex">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="情感状况" prop="isSingle">
            <el-radio-group v-model="ruleForm.isSingle">
              <el-radio :label="1">单身</el-radio>
              <el-radio :label="0">热恋期</el-radio>
              <el-radio :label="2">已婚</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="个性签名" prop="sign">
            <el-input type="textarea" v-model="ruleForm.sign"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="头像设置" name="2">
        <el-upload
          style="margin-top: 20px;"
          :multiple="false"
          :file-list="fileList"
          :show-file-list="false"
          :before-upload="beforeUpload"
          :http-request="uploadActionZip"
          :on-remove="handleRemoveFile"
          class="editor-slide-upload"
          action="/api/u/uploadIcon"
          accept=".jpg, .jpeg, .png">
          <el-button size="small" type="normal" icon="el-icon-upload">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过300kb</div>
        </el-upload>
        <div class="icon-box">
          <img class="icon-user" :src="this.userInfo.icon" alt="">
          <div class="line"></div>
          <div class="show">
            <p>头像预览</p>
            <img class="big-icon" :src="userInfo.icon" alt="">
            <span>大尺寸头像 80*80像素</span>
            <img class="sm-icon" :src="userInfo.icon" alt="">
            <span>小尺寸头像 45*45像素</span>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="密码设置" name="3">
        <el-form :model="pswForm" :rules="rules" ref="pswForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="新密码" prop="password">
            <el-input v-model="pswForm.password" type="password" style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="密码确认" prop="repassword">
            <el-input v-model="pswForm.repassword" type="password"  style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitFormPsw('pswForm')">确认修改</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import axios from 'axios'

    export default {
        name: "setting",
        data() {
            return {
                pswForm:{
                    repassword:'',
                    password:'',

                },
                fileList: [],
                action: '',
                userInfo: {},
                eduList: [
                    '大专',
                    '本科',
                    '研究生',
                    '硕士',
                    '博士',
                    '其他',
                ],
                activeName: '1',
                ruleForm: {
                    username: '',
                    nickname: '',
                    city: '',
                    birth: '',
                    education: '',
                    school: '',
                    id: '',
                    sex: '',
                    isSingle: '',
                    sign: '',
                },
                rules: {
                    username: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    city: [
                        {min: 1, max: 20, message: '长度最多二十个字符', trigger: 'blur'},
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    nickname: [
                        {min: 1, max: 20, message: '昵称长度最多二十个字符', trigger: 'blur'},
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    repassword: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted() {
            this.init()

        },
        methods: {
                beforeUpload(file) {
                    const sizeLimit = file.size/1024 > 300
                    console.log(file.size/1024)
                    if (sizeLimit) {
                        this.$message.warning('上传文件大小不能超过 300k!')
                    }

                    return !sizeLimit
                },
            async uploadActionZip(e) {
                let fd = new FormData(); //参数的格式是formData格式的
                fd.append("file", e.file); //参数
                const service = axios.create({
                    timeout: 60000,
                    responseType: 'json',
                    // withCredentials: true, // 是否允许带cookie这些
                    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
                    headers: {
                        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary9Y6udNDeSVsokdDf',
                    }
                })
                service({url: e.action, method: 'post', data: fd}).then((res) => {

                    if(res.data.code=='0000'){
                        this.$message.success('修改成功')
                        this.userInfo.icon=res.data.data
                        localStorage.setItem('userInfo',JSON.stringify(this.userInfo))
                    }else{
                        this.$message.error(res.message)
                    }
                }, err => {
                    // reject(err)
                    console.log(err, 'err')
                })
            },
            handleRemoveFile(){},
            init() {
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
                let {username, nickname, city, birth, education, school, id, sex, isSingle, sign} = this.userInfo
                this.ruleForm = {
                    username, nickname, city, birth, education, school, id, sex, isSingle, sign
                }
            },
            handleClick(val) {
                console.log(val)
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.changeUserInfo()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitFormPsw(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.pswForm.password!==this.pswForm.repassword){
                            this.$message.error('两次密码输入不一致！')
                            return
                        }
                        this.changePsw()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async changePsw(){
                let obj={
                    ...this.pswForm,
                    id:this.userInfo.id
                }
                let res=await this.API.user.changePsw(obj)

                if (res.code == '0000') {

                    this.$message.success(res.message)
                }else{
                    this.$message.error(res.message)
                }

                this.$refs['pswForm'].resetFields();
            },
            async changeUserInfo() {
                let res = await this.API.userSetting.changeUserInfo(this.ruleForm)
                if (res.code == '0000') {
                    let obj={
                        ...this.userInfo,
                        ...this.ruleForm
                    }
                    localStorage.setItem('userInfo',JSON.stringify(obj))
                    this.$message.success(res.message)
                } else {
                    this.$message.error(res.message)
                }
            },
        },
    }
</script>

<style scoped lang="scss">
  .setting-content {
    min-height: 642px;
    width: 984px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-left: 16px;
    padding: 20px;
  }
  .icon-box{
    display: flex;
    margin-top: 24px;
    .icon-user{
      width: 240px;
      height: 240px;
      border: 1px solid #D9D9D9;
      border-radius: 8.57px;
    }
    .line{
      width: 1px;
      height: 240px;
      background: #ECECEC;
      margin: 0 24px;
    }
    .show{
      .big-icon{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 1px solid #D9D9D9;
        margin-top: 20px;
        margin-bottom: 12px;
      }
      .sm-icon{
        margin-top: 20px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid #D9D9D9;
        margin-bottom: 12px;
      }
      display: flex;
      flex-direction: column;
      p{
        font-family: PingFangSC-Medium;
        font-weight: 600;
        font-size: 12px;
        color: #333333;
      }
      span{
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #00000073;
        line-height: 18px;
      }
    }

  }

</style>
