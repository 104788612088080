<template>
  <div></div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return{

            }
        },
        mounted() {
          if(this.$route.query.phone){
              this.login(this.$route.query.phone)
          }
        },
        methods:{

            async login(phone) {
                let res = await this.API.user.loginNoPsw({
                    username:phone
                })
                console.log(res)
                if (res.code == '0000') {
                    if (this.rememberMe) {
                        const {username, password} = {...this.ruleForm}
                        localStorage.setItem('saveUser', JSON.stringify({username, password}))
                    } else {
                        localStorage.removeItem('saveUser')
                    }
                    setTimeout(() => {
                        this.$router.push('/Home')
                    }, 600)
                } else {
                    this.$message.error(res.message)
                }
            },
        },
    }
</script>

<style scoped>

</style>
