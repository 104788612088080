VueRouter.prototype.push = function push(location, onComplete, onAbort) {
    this.history.push(location, onComplete, onAbort);
};
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import login from "../views/login/login.vue";
import forget from "../views/login/forget.vue";
import signIn from "../views/login/signIn.vue";
import SuperGeek from "../views/SuperGeek/index.vue";
import Article from "../views/Article/index.vue";
import WriteArticle from "../views/WriteArticle/index.vue";
import MyHome from "../views/MyHome/index.vue";

import setting from "../views/setting/index.vue";
import loginNoPsw from "../views/wthiteLogin/index.vue";


Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/Home",
        meta: {
            noTitle: "登录页",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: false,
            keepAlive: false,
        },
    },
    {
        path: "/Login",
        name: "Login",
        component: login,
        meta: {
            noTitle: "登录",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: false,
            keepAlive: false,

        },
    },
    {
        path: "/forget",
        name: "forget",
        component: forget,
        meta: {
            noTitle: "忘记密码",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: false,
            keepAlive: false,

        },
    },
    {
        path: "/signIn",
        name: "signIn",
        component: signIn,
        meta: {
            noTitle: "注册",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: false,
            keepAlive: false,

        },
    },
    {
        path: "/Home",
        name: "Home",
        component: Home,
        meta: {
            parentPath: "home",
            parent: "首页 ",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: true,
            keepAlive: false,
        },
    },

    {
        path: "/SuperGeek",
        name: "SuperGeek",
        component: SuperGeek,
        meta: {
            noTitle: "知识达人",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: true,
            keepAlive: false,
        },
    },
    {
        path: "/writeArticle",
        name: "writeArticle",
        component: WriteArticle,
        meta: {
            noTitle: "知识达人",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: true,
            keepAlive: false,
        },
    },
    {
        path: "/article/:id",
        name: "article",
        component: Article,
        meta: {
            noTitle: "文章",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: false,
            keepAlive: false,
        },
    },
    {
        path: "/myhome",
        name: "myhome",
        component: MyHome,
        meta: {
            noTitle: "我的空间",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: true,
            keepAlive: false,
        },
    },
    {
        path: "/setting",
        name: "setting",
        component: setting,
        meta: {
            noTitle: "基础设置",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: true,
            keepAlive: false,
        },
    },
    {
        path: "/loginNoPsw",
        name: "loginNoPsw",
        component: loginNoPsw,
        meta: {
            noTitle: "登录",
            btnPermissions: ["admin", "supper", "normal"],
            isScroll: true,
            keepAlive: false,
        },
    },

];

const router = new VueRouter({
    routes,
});

export default router;
