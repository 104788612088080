import Filter from "./filter";
// 一行一条，filter 移动到独立的文件中处理
export default {
    gate: "api",
    version: "", // 默认版本
    module: "", // 默认模块
    key: "", // 设置发送数据的类型是JSON还是BLOB
    filter: function () {
    }, // 默认拦截过滤器
    // inter: function () {}, // 默认拦截器
    list: {
        /**用户 */
        user: {
            login: {
                module: "",
                url: "/u/login",
                type: "post",
                filter: Filter.saveUser,
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",
            }, //登录
            loginNoPsw: {
                module: "",
                url: "/u/thirdLogin",
                type: "post",
                filter: Filter.saveUser,
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",
            }, //登录
            signinNoPsw: {
                module: "",
                url: "/u/thirdRegister",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",
            }, //登录
            logout: {
                module: "",
                url: "/u/logout",
                type: "get",
            }, //登出
            register: {
                module: "",
                url: "/u/register",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            }, //注册
            changePsw: {
                module: "",
                url: "/u/updatePass",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
            verificationCode: {
                module: "",
                url: "/sms/verify-code",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
            forgetPsw: {
                module: "",
                url: "/u/forget",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
        },
        userSetting:{
            getLevel:{
                module: "",
                url: "/u/newUpLevel",
                type: "get",
            },
            articleList:{
                module: "",
                url: "/article/manage/list",
                type: "get",
            },
            adminManageList:{
                module: "",
                url: "/article/adminManage/list",
                type: "get",
            },
            userList:{
                module: "",
                url: "/u/manage/list",
                type: "get",
            },
            examine:{
                module: "",
                url: "/article/manage/examine",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
            articleDelete:{
                module: "",
                url: "/article/delete",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
            recommend:{
                module: "",
                url: "/article/manage/recommend",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
            levelUp:{
                module: "",
                url: "/u/upLevel",
                type: "post",
            },
            changeUserInfo:{
                module: "",
                url: "/u/updateUserInfo",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",
            },
            changeStatus:{
                module: "",
                url: "/u/manage/changeStatus",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",
            },
            adminRole:{
                module: "",
                url: "/u/manage/adminRole",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",
            }
        },
        home:{

            activeTalent:{
                module: "",
                url: "/activeTalent",
                type: "get",
            },
            articleList:{
                module: "",
                url: "/article/manage/list",
                type: "get",
            },

        },
        article:{

            articleList:{
                module: "",
                url: "/article/manage/list",
                type: "get",
            },
            lableList:{
                module: "",
                url: "/lable/list",
                type: "get",
            },
            myArticleReply:{
                module: "",
                url: "/comment/myArticleReply",
                type: "get",
            },
            getTagList:{
                module: "",
                url: "/lable/list",
                type: "get",
            },
            writeArticle:{
                module: "",
                url: "/article/writeArticle",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
            commentLike:{
                module: "",
                url: "/comment/like",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
            commentDel:{
                module: "",
                url: "/article/deleteComment",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
            articleLike:{
                module: "",
                url: "/article/like",
                type: "post",
                contentType: "application/x-www-form-urlencoded;charset=UTF-8",

            },
        },
    },
};
