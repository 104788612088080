<template>
    <div class="article_container">
        <div v-if="ifEmpty" class="empty"><span> 您还未发布过文章~ </span></div>
        <div v-else class="articles">
            <div class="article" v-for="(item,index) in articles" :key="index">
                <div class="info">
                    <span>{{ item.nickname }}</span>
                    <span>{{ item.createTime }}</span>
                </div>
                <div class="title" @click="articleNav(item.id)"> {{ item.articleTitle }} </div>
                <div class="content">
                    <div class="html" v-html="HTMLDecode(item.articleContent)"></div>
                </div>
            </div>
        </div>
        <el-pagination
            v-if="!ifEmpty"
            :current-page="page"
            background
            layout="prev, pager, next"
            :page-count	="pages"
            @current-change="changePage"
        >
        </el-pagination>
    </div>
</template>

<script>
    import { getArticleList } from '@/api/article.js';
    export default {
        name: 'MyArticle',
        data() {
            return {
                page: 1,
                total: 1,
                pages: 1,
                articles: [],
                userInfo:{},
            };
        },
        computed: {
            ifEmpty: function () {
                return this.articles.length == 0 ? true : false;
            },
        },
        methods: {
            async changePage(val) {
                this.page = val;
                const { data } = await this.API.article.articleList({
                    page: this.page,
                    userId: this.userInfo.id,
                });
                this.articles = data.list;
                this.pages = data.pages;

                document.body.scrollTop = 0
                document.getElementsByClassName('articles')[0].scrollTop = 0
            },

            HTMLDecode(text) {
                var output = '';
                if (text) {
                    var temp = document.createElement('div');
                    temp.innerHTML = text;
                    output = temp.innerText;
                }
                return output.replace(/<[^>]+>/g, '');
            },
            articleNav(id) {
                this.$router.push(`/article/${id}`);
            },
        },
        mounted: async function () {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

            const { data } = await this.API.article.articleList({
                page: this.page,
                userId: this.userInfo.id,
            });
            this.articles = data.list;
            this.pages = data.pages;
        },
    };
</script>

<style lang="scss" scoped>

    .article_container {
        position: relative;
        height: 100%;
    }
    ::v-deep .el-pagination {
      padding: 12px;
      width: 100%;
      background-color: #fff;
    }
    .empty {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        span {
            color: rgb(153, 153, 153);
            font-size: 14px;
        }
    }

    .articles {
        height: 100%;
        overflow-y: scroll;
        div {
            margin-bottom: 20px;
        }
        .article {
            padding: 30px;
            background-color: #fff;
            border-radius: 4px;
            border-bottom: 1px dotted #ccc ;
            .info {
                span:first-child {
                    padding: 0 5px;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    letter-spacing: 0;
                }
                span:last-child {
                    padding: 0 5px;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 12px;
                    color: #666666;
                    letter-spacing: 0;
                    line-height: 16px;
                    border-left: 1px solid #e1e1e1;
                }
            }
            .title {
                font-family: PingFangSC-Medium;
                font-weight: 600;
                font-size: 20px;
                color: #333333;
                letter-spacing: 0;
                cursor: pointer;
            }

            .content {
                .html {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #999999;
                    letter-spacing: 0;
                }
            }
        }
    }
</style>
