import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {
        scrollSet(state, playload) {
            state.isScroll = playload;
        },
    },
    actions: {
        setInfo(context, val) {
            context.commit("WAYBILLSET", val);
        },
    },
    modules: {},
    getters: {
        scrollget(state) {
            return state.isScroll;
        },
    },
});
