<template>
    <div class="article_container">
        <div v-if="!ifEmpty" class="header">
            我的评论
            <div class="line"></div>
        </div>
        <div v-if="ifEmpty" class="empty"><span> 您还未评论过文章~ </span></div>
        <div v-else class="comments">
            <div class="comment" v-for="(item,index) in comments" :key="index">
                <div class="name"> {{userInfo.nickname}} </div>
                <div class="time"> {{ item.createTime }} </div>
                <div>回复: {{ HTMLDecode(item.content) }} </div>
                <div class="article" v-if="item.articleTitle" @click="articleNav(item.articleId)">
                    <div class="title">{{ item.articleTitle }}</div>
                    <div class="html">{{ HTMLDecode(item.articleContent) }}</div>
                </div>
              <div class="article" style="display: flex; align-items: center; justify-content: center;" v-if="!item.articleTitle">
                <div class="html">当前文章已删除</div>
              </div>
            </div>
        </div>
        <el-pagination
          class="pagination"
          v-if="!ifEmpty"
          :current-page="page"
          background
          layout="prev, pager, next"
          :page-count	="pages"
          @current-change="changePage"
        >
        </el-pagination>

    </div>
</template>

<script>
    import { getMyArticleReply } from '@/api/article.js';
    export default {
        data() {
            return {
                page: 1,
                pages: 1,
                total: 1,
                comments: [],
                userInfo:{},
            };
        },
        computed: {
            ifEmpty: function () {
                return this.comments.length == 0 ? true : false;
            },
        },
        methods: {
            async changePage(val) {
                this.page=val
                const { data } = await this.API.article.myArticleReply({
                    page: this.page,
                    userId: this.userInfo.id,
                });
                this.comments = data.list;
                this.pages = data.pages;
                document.body.scrollTop = 0

                document.getElementsByClassName('comments')[0].scrollTop = 0

            },
            HTMLDecode(text) {
                var output = '';
                if (text) {
                    var temp = document.createElement('div');
                    temp.innerHTML = text;
                    output = temp.innerText;
                }
                return output.replace(/<[^>]+>/g, '');
            },
            articleNav(id) {
                this.$router.push(`/article/${id}`);
            },
        },
        mounted: async function () {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

            const { data } = await this.API.article.myArticleReply({
                page: this.page,
                userId: this.userInfo.id,
            });
            this.comments = data.list;
            this.pages = data.pages;
        },
    };
</script>

<style lang="scss" scoped>
    .line {
        position: absolute;
        width: 44px;
        height: 2px;
        background: #165dff;
        border-radius: 2px;
        left: 15px;
        bottom: -2px;
    }
    ::v-deep .el-pagination {
      padding: 12px;
        width: 100%;
        background-color: #fff;
    }
    .article_container {
        position: relative;
        height: 100%;
    }

    .empty {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        span {
            color: rgb(153, 153, 153);
            font-size: 14px;
        }
    }
    .header {
        position: relative;
        height: 30px;
        padding: 10px;
        background-color: #fff;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .comments {
        height: 92%;
        overflow-y: scroll;
        .comment {
            padding: 20px 35px;
            background-color: #fff;
            margin-bottom: 10px;
            border-bottom: 1px dotted #ccc;

            div {
                margin-bottom: 8px;
            }
            .name {
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 16px;
                color: #333333;
                letter-spacing: 0;
            }
            .time {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                letter-spacing: 0;
                line-height: 16px;
            }
            .article {
                width: 584px;
                height: 67px;
                background: #f9fafd;
                border: 1px solid #e0e0e0;
                border-radius: 4px;
                padding: 10px;
                cursor: pointer;
                .title {
                    color: #333333;
                    font-size: 18px;
                    font-weight: 600;
                    letter-spacing: 0;
                }
                .html {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    font-family: PingFangSC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #999999;
                    letter-spacing: 0;
                }
            }
        }
    }
</style>
