<template>
  <div class="editor_container">
    <div class="form_container">
      <div class="artilce-title">
        <div class="line"></div>
        请开始你的写作
      </div>
      <div>
        <el-form ref="form" :model="form"  :rules="rules">
          <el-form-item label="标签" label-width="80px" prop="label">
            <el-select v-model="form.label" placeholder="请选择">
              <el-option
                v-for="tag in tags"
                :key="tag.id"
                :label="tag.name"
                :value="tag.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="匿名发表" label-width="80px" prop="anonymousSwitch">
            <el-switch
              v-model="form.anonymousSwitch"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item label="" prop="articleTitle">
            <el-input class="title-write" v-model="form.articleTitle" placeholder="请输入标题"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div style="height: 500px">
      <el-upload
        :multiple="false"
        :file-list="fileList"
        :show-file-list="false"
        :http-request="uploadActionZip"
        class="editor-slide-upload"
        action="api/api/upload"
        accept=".jpg, .jpeg, .png"
        style="height: 1px"
      >
      </el-upload>
      <quill-editor
        :options="editorOption" class="editor-main" ref="myQuillEditor" v-model="articleContent"/>
    </div>
    <div class="btm-btn"><el-button v-loading="loadingBtn" class="sub-btn" type="primary"  @click="submit">发布</el-button></div>
  </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor} from 'vue-quill-editor';
    import {getTagsList, writeArticle} from '@/api/article.js';
    import axios from 'axios'

    export default {
        components: {
            quillEditor,
        },
        data() {
            const toolbarOptions = [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{'header': 1}, {'header': 2}],               // custom button values
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                [{'direction': 'rtl'}],                         // text direction

                [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
                [{'header': [1, 2, 3, 4, 5, 6, false]}],

                [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                [{'font': []}],
                [{'align': []}],
                ['link', 'image', 'video'],
                ['clean']                                         // remove formatting button
            ]
            return {
                fileList:[],
                editorOption: {
                    placeholder: '请输入...',
                    modules: {
                        toolbar: {
                            container: toolbarOptions,  // 工具栏
                            handlers: {
                                'image': function (value) {
                                    if (value) {
                                        document.querySelector(".editor-slide-upload input").click();

                                    } else {
                                        this.quill.format('image', false);
                                    }
                                }
                            }
                        }
                    }
                },
                loadingBtn:false,
                tags: [],
                articleContent: '',
                form: {
                    anonymousSwitch: 0,
                    articleTitle: '',
                    label: '',
                },
                rules: {
                    label: [{required: true, message: '请选择标签'}],
                    articleTitle: [{required: true, message: '请输入标题'},
                        { min: 1, max: 25, message: '标题长度最多二十五个字符', trigger: 'blur' }],
                },
            };
        },
        methods: {

            async uploadActionZip(e) {
                let fd = new FormData(); //参数的格式是formData格式的
                fd.append("file", e.file); //参数
                const service = axios.create({
                    timeout: 60000,
                    responseType: 'json',
                    // withCredentials: true, // 是否允许带cookie这些
                    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
                    headers: {
                        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary9Y6udNDeSVsokdDf',
                    }
                })
                service({url: e.action, method: 'post', data: fd}).then((res) => {

                    if(res.data.code=='0000'){
                        let quill = this.$refs.myQuillEditor.quill;
                        // 如果上传成功
                            // 获取光标所在位置
                            let length = quill.getSelection().index;
                            // 插入图片，res为服务器返回的图片链接地址
                            //测试环境
                            // quill.insertEmbed(length, "image",'http://172.19.4.183:8284'+ res.data.data);
                            //双瑞正式
                            quill.insertEmbed(length, "image",'http://bbs.sunrui-wanji.com'+ res.data.data);
                            //工业互联网-正式
                            // quill.insertEmbed(length, "image",'http://bbs.omp.ciicp.com'+ res.data.data);
                            // 调整光标到最后
                            quill.setSelection(length + 1);
                            // 提示信息，需引入Message
                    }else{
                        this.$message.error(res.message)
                    }
                }, err => {
                    // reject(err)
                    console.log(err, 'err')
                })
            },
            async submit() {
                await this.$refs.form.validate();

                this.loadingBtn=true
                const URLformData = new URLSearchParams()
                URLformData.append('anonymousSwitch', this.form.anonymousSwitch)
                URLformData.append('articleTitle', this.form.articleTitle)
                URLformData.append('label', this.form.label)
                URLformData.append('articleContent', this.articleContent)
                const res = await writeArticle(URLformData);
                if (res.data.code == '0000') {
                    this.$message.success(res.data.message)
                    this.$router.push('/SuperGeek')
                } else {
                    this.$message.error(res.data.message)
                }
                this.loadingBtn=false
            },
        },
        mounted: async function () {
            const tagsRes = await this.API.article.getTagList({
                page: 1,
            });
            this.tags = tagsRes.data.list;
        },
    };
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
  /deep/ .title-write {
    .el-input__inner {
      border-radius: 0;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      border-bottom-width: 1px;

    }
  }

  .editor_container {
    width: 1180px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;

    .form_container {
      .artilce-title {
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 32px;
        display: flex;
        align-items: center;

        .line {
          width: 4px;
          height: 16px;
          background: #165DFF;
          margin-right: 12px;
        }
      }
    }
    .editor-main{
      height: 80%;
    }
    .btm-btn{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .sub-btn{
        width: 76px;
        height: 36px;
        background: #165DFF;
        border-radius: 4px;
      }
    }
  }
</style>
