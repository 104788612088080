<template>
  <div class="content">
    <div class="title" @click="goHome"><img src="../../assets/shuangruilogo-sm.png" alt="">开发者社区</div>
    <div class="login">
      <div class="top-tab">
        <div class="tab-item" :class="isActive==1?'tab-active':''" @click="handleChangeActive(1)">登录</div>
<!--        <div class="tab-item" :class="isActive==2?'tab-active':''" @click="handleChangeActive(2)">注册</div>-->
      </div>
      <div class="item" v-show="isActive=='1'">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item label="" prop="username">
            <el-input prefix-icon="el-icon-phone" placeholder="请输入手机号" v-model="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input type="password" prefix-icon="el-icon-lock" placeholder="请输入密码"
                      v-model="ruleForm.password"></el-input>
          </el-form-item>
          <el-form-item prop="isLock">
            <slider v-model="ruleForm.isLock" @change="handlerLock"></slider>
          </el-form-item>
          <el-form-item label="" prop="rememberMe">
            <div style="display: flex; justify-content: space-between">
              <el-checkbox v-model="rememberMe">记住我</el-checkbox>
              <el-button type="text" @click="forget('ruleForm')">忘记密码?</el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button class="login-btn" type="primary" @click="submitForm('ruleForm')">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="item" v-show="isActive==2">
        <el-form :model="signForm" :rules="rules" ref="signForm" class="demo-ruleForm">
          <el-form-item label="" prop="username">
            <el-input prefix-icon="el-icon-phone" placeholder="请输入手机号" v-model="signForm.username"></el-input>
          </el-form-item>
          <el-form-item label="" prop="nickname">
            <el-input prefix-icon="el-icon-edit-outline" placeholder="请输入昵称" v-model="signForm.nickname"></el-input>
          </el-form-item>
          <el-form-item label="" prop="city">
            <el-input prefix-icon="el-icon-office-building" placeholder="请输入城市" v-model="signForm.city"></el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input type="password" prefix-icon="el-icon-lock" placeholder="请输入密码"
                      v-model="signForm.password"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="login-btn" type="primary" @click="signIn('signForm')">注册</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
    import slider from "./component/silderVerifyCode.vue";

    export default {
        name: "login",
        components: {slider},

        data() {
            //校验滑动验证
            const checkStatus = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("请拖动滑块完成验证"));
                } else {
                    if (
                        this.ruleForm.username == "" ||
                        this.ruleForm.password == "" ||
                        !this.ruleForm.username ||
                        !this.ruleForm.password
                    ) {
                        setTimeout(() => {
                            this.ruleForm.isLock = false;
                            this.$refs["ruleForm"].validateField("username");
                            this.$refs["ruleForm"].validateField("password");
                            return callback(new Error("验证未通过"));
                        }, 1);
                    }
                    callback();
                }
            };
            return {
                isActive: 1,
                signForm: {
                    username: '',
                    password: '',
                    nickname: '',
                    city: '',
                },
                ruleForm: {
                    username: '',
                    password: '',
                    isLock: false,
                },
                rememberMe: false,
                rules: {
                    isLock: [{validator: checkStatus, trigger: "blur"}],

                    username: [
                        {required: true, message: '不能为空', trigger: 'blur'},
                    ],
                    nickname: [
                        {min: 1, max: 20, message: '昵称长度最多二十个字符', trigger: 'blur'},
                        {required: true, message: '不能为空', trigger: 'blur'},
                    ],
                    city: [
                        {min: 1, max: 20, message: '长度最多二十个字符', trigger: 'blur'},
                        {required: true, message: '不能为空', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '不能为空', trigger: 'blur'}
                    ],

                }
            }
        },
        mounted() {
            this.getSaveUserData()
            this.isActive = this.$route.query.active ? this.$route.query.active : 1
        },
        methods: {
            //滑块验证
            handlerLock(data) {
                if (data) {
                    this.$refs["ruleForm"].validateField("isLock");
                }
            },
            getSaveUserData() {
                const saveUser = localStorage.getItem('saveUser')
                if (saveUser) {
                    let data = JSON.parse(saveUser)
                    this.rememberMe = true
                    this.ruleForm.username = data.username
                    this.ruleForm.password = data.password
                }
            },
            handleChangeActive(val) {
                this.isActive = val
                if (this.$refs['ruleForm']) this.$refs['ruleForm'].resetFields();
                if (this.$refs['signForm']) this.$refs['signForm'].resetFields();
            },
            forget() {
                this.$router.push({name: 'forget', query: {loginFlag: 1, username: ''}})

            },
            goHome() {
                this.$router.push('/Home')
            },
            signIn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.signInSubmit()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.login()
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async signInSubmit() {
                let res = await this.API.user.register(this.signForm)
                if (res.code == '0000') {
                    this.$message.success('注册成功，请登录！')
                    setTimeout(() => {
                        this.isActive = 1
                    }, 500)
                } else {
                    this.$message.error(res.message)
                }
            },
            async login() {
                let res = await this.API.user.login(this.ruleForm)
                console.log(res)
                if (res.code == '0000') {
                    if (this.rememberMe) {
                        const {username, password} = {...this.ruleForm}
                        localStorage.setItem('saveUser', JSON.stringify({username, password}))
                    } else {
                        localStorage.removeItem('saveUser')
                    }
                    this.$message.success('登录成功！')
                    setTimeout(() => {
                        this.$router.push('/Home')
                    }, 1000)
                } else {
                    this.$message.error(res.message)
                }
            },
        },
    }
</script>

<style scoped lang="scss">

  .content {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/bg.png");
    background-size: 100% 100%;
    margin: 0 auto;
    display: flex;
    padding: 24px;
    justify-content: space-between;
    position: relative;

    .title {
      display: flex;
      align-items: center;
      position: absolute;
      top: 40px;
      left: 80px;
      font-family: PingFangSC-Medium;
      font-weight: 600;
      font-size: 28.13px;
      color: #000000;
      cursor: pointer;

      img {
        width: 45px;
        margin-right: 8px;
      }
    }

    .login {
      width: 480px;
      height: 600px;
      box-shadow: 0 2px 30px 6px #c0c3cb26;
      border-radius: 12px;
      background: #fff;
      position: absolute;
      right: 270px;
      top: 210px;
      padding: 80px;

      .top-tab {
        display: flex;
        margin-bottom: 24px;

        .tab-item {
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 24px;
          color: #999999;
          margin: 12px;
          padding-bottom: 8px;
        }

        .tab-active {
          font-family: PingFangSC-SNaNpxibold;
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          border-bottom: 5px solid #165DFF;
        }
      }
    }

    .item {
      display: flex;
      width: 100%;

      span {
        color: #409EFF;
        cursor: pointer;
      }
    }

    .el-button--text /deep/ {
      span {
        color: gray !important;
      }
    }
  }

  .Sign-in {
    margin-top: 24px;
  }

  .demo-ruleForm {
    width: 100%;
  }

  .login-btn {
    margin-top: 36px;
    width: 100%;
    height: 60px;
    background: #165DFF !important;

  }
</style>
<style>
  .el-checkbox__label {
    color: gray !important;
  }
</style>
