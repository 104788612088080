<template>
  <div id="header">
    <div class="logo" v-if="winWidth" @click="handleGoToHome"><img src="../assets/shuangruilogo-sm.png" alt="">海绵钛行业工业互联网平台|开发者社区
    </div>
    <div class="logo" v-if="!winWidth" @click="handleGoToHome"><img src="../assets/shuangruilogo-sm.png" alt=""></div>
    <div class="header-content">
      <div style="display: flex">
        <div class="item" :class="isActive==item.eName?'activeMenu':''" v-if="item.isShow"
             v-for="(item,index) in menuList" :key="index" @click="handleGoToPage(item.url)">{{item.name}}
        </div>
      </div>
      <el-dropdown v-if="userInfo">
      <span class="el-dropdown-link">
      <div class="already-login">
        <img :src="userInfo.icon" alt="">
          <div class="item-name">{{userInfo.nickname}}</div>
      </div>
      </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleGotoSet">基本设置</el-dropdown-item>
          <el-dropdown-item @click.native="logout">登出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div v-if="!userInfo" style="display: flex" class="not-login">
      <div class="item-log" @click="handleLogin">登录</div>
      <!--      <div class="item-sign" @click="signIn">注册</div>-->
    </div>
  </div>
</template>

<script>
    export default {
        name: "pubHeader",
        data() {
            return {
                userInfo: null,
                menuList: [
                    {
                        name: '开发者社区-首页',
                        eName: 'Home',
                        url: "/Home",
                        isShow: true,
                    },
                    {
                        name: '我的空间',
                        eName: 'myhome',
                        url: "/myhome"
                    },
                    {
                        name: '知识达人',
                        eName: 'SuperGeek',
                        url: "/SuperGeek",
                        isShow: true
                    },
                ],
            }
        },
        computed: {
            winWidth() {
                return window.innerWidth > 1500 ? true : false;
            },
            isActive() {
                return this.$route && this.$route.name;
            },
        },
        watch: {},
        mounted() {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
            this.changeShowState()
            let that = this
            window.addEventListener('setItemEvent', function (e) {
                const newdata = JSON.parse(e.newValue)
                that.userInfo = newdata
                that.changeShowState()

            })
        },
        methods: {
            changeShowState() {
                if (this.userInfo) {
                    this.menuList.forEach(ele => {
                        ele.isShow = true
                    })
                } else {
                    this.menuList.forEach(ele => {
                        if (ele.name == '我的空间') {
                            ele.isShow = false
                        } else {
                            ele.isShow = true
                        }
                    })
                }
            },
            async logout() {
                localStorage.removeItem('userInfo')
                this.userInfo = null
                this.changeShowState()

                setTimeout(() => {
                    this.$message.success('退出成功！')
                    this.token = null
                    this.$router.push('/Home')
                    this.userInfo = null
                }, 100)
            },
            handleGoToHome() {
                window.open('https://gy.sunrui-wanji.com/#/mod', '_self')
            },
            handleGoToPage(url) {
                this.$router.push(url);
            },
            handleLogin() {

                this.$router.push({name: 'Login', query: {active: 1}})
            },
            handleGotoSet() {
                this.$router.push('/setting')
            },
            signIn() {
                this.$router.push({name: 'Login', query: {active: 2}})
            },
        },

    }
</script>

<style scoped lang="scss">
  #header {
    width: 100%;
    height: 60px;
    background: #fff;
    margin-bottom: 18px;
    position: relative;

    .logo {
      cursor: pointer;
      position: absolute;
      margin: 16px 20px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        margin-right: 8px;
      }

    }

    .header-content {
      width: 1170px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item-name {
        cursor: pointer;
        padding: 0 20px;
        height: 100%;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 20px;
      }

      .item {
        width: 160px;
        display: flex;
        align-content: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 20px;
        height: 100%;
        line-height: 60px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
      }

      .activeMenu {

        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #165DFF;
        border-bottom: 3px solid #165DFF;
      }

      .item:hover {
      }

      .already-login {
        display: flex;
        align-items: center;

        img {
          width: 45px;
          height: 45px;
          border-radius: 25px;
          cursor: pointer;
        }
      }
    }

    .not-login {
      position: absolute;
      right: 0;
      top: 0;

      .item-log {
        width: 100px;
        height: 60px;
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: center;
      }

      .item-sign {
        cursor: pointer;
        width: 100px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #165DFF;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
      }
    }
  }

</style>
