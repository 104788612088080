<template>
  <div class="geek_container">
    <div class="content_container">
      <div class="left">
        <div class="top-search">
          <el-button class="write_button" type="primary" icon="el-icon-devops" @click="writeArticle">写一篇文章</el-button>
          <div class="search_box">
            <el-input prefix-icon="el-icon-search" v-model="searchValue" placeholder="搜索你想看的文章"
                      @keyup.enter.native="searchArticle"
            >
            </el-input>
          </div>
        </div>
        <!--        <div class="sort">-->
        <!--          <span>排序规则</span>-->
        <!--          <span @click="changeSort(0)">时间</span>-->
        <!--          <span @click="changeSort(1)">点赞数</span>-->
        <!--          <span @click="changeSort(2)">评论数</span>-->
        <!--        </div>-->
        <div class="content">
          <div class="article" v-for="(item,index) in articles" :key="index">
            <div class="line-top">
              <img :src="item.anonymousSwitch==1?user:item.icon" alt=""/>
              <div class="name">{{item.anonymousSwitch==1?'匿名用户':item.nickname}}</div>
              <div class="line"></div>
              <div class="time">{{item.createTime}}</div>
              <div class="line"></div>
              <span class="tag"
                    :style="{backgroundColor:colorMapper.get(item.label)}">{{tagsMapper.get(item.label)}}</span>
            </div>
            <div class="article-banner">
              <div style="cursor: pointer" @click="articleNav(item.id)">
                                <span class="title"
                                >{{ item.articleTitle }}</span
                                >
                <div class="ql-editor ">
                  <div class="text-box">
                    {{HTMLDecode(item.articleContent)}}
                  </div>
                </div>
              </div>
              <div class="btm-btn">
                <div class="item"><img src="../../assets/img/dianzan.png" alt="">{{
                  item.likeCount==null?0:item.likeCount }}
                </div>
                <div class="item"><img src="../../assets/img/liulna.png" alt="">{{
                  item.browseCount==null?0:item.browseCount }}
                </div>
                <div class="item"><img src="../../assets/img/pignlun.png" alt="">{{
                  item.commentCount==null?0:item.commentCount }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="page">
          <span style="margin-right: 20px">共{{ total }}条</span>
          <span
            class="page_button"
            :class="{ disable: !hasPreviousPage }"
            @click="changePage(-1)"
          >上一页</span
          >
          <span
            class="page_button"
            style="
                            background-color: #165DFF;
                            color: white;
                            cursor: default;
                            border-color: #165DFF;
                        "
          >{{ page }}</span
          >
          <span
            class="page_button"
            :class="{ disable: !hasNextPage }"
            @click="changePage(1)"
          >下一页</span
          >
          <span style="margin: 0 20px">到第</span>
          <div class="page_input">
            <input type="text" v-model="inputPage"/>
          </div>
          <span style="margin: 0 10px">页</span>
          <span
            class="page_button"
            style="margin-left: 20px; color: black"
            @click="changePage(0)"
          >确定</span
          >
        </div>
      </div>
      <div class="right">
        <div class="right_box">
          <div class="header header-bq">标签</div>
          <div class="tags">
                        <span class="tag" :style="{background:colorList[index]}" v-for="(tag,index) in tags"
                              :key="index" @click="changeTag(tag.id)">{{
                            tag.name
                        }}</span>
          </div>
        </div>
        <div class="right_box">
          <div class="header header-tjwz">推荐文章</div>
          <div class="article-box">
            <div style="margin-bottom: 16px;" v-for="(item,index) in recommandArticle" :key="index" @click="articleNav(item.id)">
              <div class="title" :title="item.articleTitle">{{ item.articleTitle }}</div>
              <div class="btm-line">
                <div class="item"> {{item.anonymousSwitch==1?'匿名用户':item.nickname}}</div>
                <div class="item"><img src="../../assets/img/liulna.png" alt="">{{ item.browseCount==null?0:item.browseCount }}</div>
              </div>
            </div>
          </div>

        </div>
        <div class="right_box">
          <div class="header header-rmwz">月热门文章</div>
          <div class="article-box">
            <div style="margin-bottom: 16px; display: flex;" v-for="(article,index) in topArticles" :key="index" @click="articleNav(article.id)">

              <div :class="'No'">
                <img v-if="index<=4" :src="homeList[index]" alt="">
                <div style="font-size: 20px; color:#165DFF;margin-left: 8px" v-if="index>4">{{index}}. </div>
              </div>
             <div style="width: 100%">
               <div class="title" :title="article.articleTitle">{{ article.articleTitle }}</div>
               <div class="btm-line">
                 <div class="item"> {{article.anonymousSwitch==1?'匿名用户':article.nickname}}</div>
                 <div style="display: flex">
                   <div class="item" style="margin-right: 8px;"><img src="../../assets/img/liulna.png" alt="">{{ article.browseCount==null?0:article.browseCount  }}</div>
                   <div class="item"><img src="../../assets/img/dianzan.png" alt="">{{ article.likeCount==null?0:article.likeCount  }}</div>
                 </div>
               </div>
             </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import home1 from '../../assets/home1.png'
    import home2 from '../../assets/home2.png'
    import home3 from '../../assets/home3.png'
    import home4 from '../../assets/home4.png'
    import home5 from '../../assets/home5.png'
    import user from '../../assets/user.jpg'
    export default {
        name: 'SuperGeek',
        data() {
            return {
                user,
                homeList: [
                    home1,
                    home2,
                    home3,
                    home4,
                    home5,
                ],
                page: 1,
                inputPage: 1,
                regulation: 0,
                searchValue: '',
                hasPreviousPage: false,
                hasNextPage: false,

                total: 0,
                colorList: [
                    '#FA7575',
                    '#7CB1F7',
                    '#5086FF',
                    '#92D488',
                    '#FBCF6C',
                    '#B893CB',
                    '#EEAADA',
                    '#A2A0FA',
                    '#7EA9A9',

                ],
                tags: [],
                tagsMapper: null,
                colorMapper: null,
                articles: [],
                recommandArticle: [],
                topArticles: [],
                userInfo:{},
            };
        },
        methods: {
            HTMLDecode(text) {
                var output = '';
                if (text) {
                    var temp = document.createElement('div');
                    temp.innerHTML = text;
                    output = temp.innerText;
                }
                return output.replace(/<[^>]+>/g, '');
            },
            async getTags() {
                const {data} = await this.API.article.lableList({
                    page: 1,
                });
                this.tags = data.list;
                this.tagsMapper = new Map()
                this.colorMapper = new Map()
                this.tags.forEach((tag, index) => {
                    this.tagsMapper.set(tag.id, tag.name,)
                    this.colorMapper.set(tag.id, this.colorList[index],)
                })
                console.log(this.colorMapper)
            },
            async getRecommand() {
                const {data} = await this.API.article.articleList({
                    isRecommend: 1,
                    page: 1,
                    regulation: 0,
                });
                this.recommandArticle = data.list;
            },
            async getTopArticles() {
                const {data} = await this.API.article.articleList({
                    isMonth: 1,
                    page: 1,
                });
                this.topArticles = data.list;
            },
            async searchArticle() {
                this.page = 1;
                this.regulation = 0;
                const {data} = await this.API.article.articleList({
                    page: this.page,
                    regulation: this.regulation,
                    articleTitle: this.searchValue,
                });
                this.total = data.total;
                this.articles = data.list;
                this.page = data.pageNum;
                this.hasPreviousPage = data.hasPreviousPage;
                this.hasNextPage = data.hasNextPage;
            },

            async getArticles() {
                const {data} = await this.API.article.articleList({
                    page: this.page,
                    regulation: this.regulation,
                });
                this.total = data.total;
                this.articles = data.list;
                this.page = data.pageNum;
                this.hasPreviousPage = data.hasPreviousPage;
                this.hasNextPage = data.hasNextPage;

                document.body.scrollTop = 0
                document.getElementsByClassName('content')[0].scrollTop = 0
            },
            async changePage(num) {
                if (num == 0) {
                    this.page = this.inputPage;
                    this.getArticles();
                } else {
                    this.page = this.page + num;
                    this.getArticles();
                }
            },
            async changeSort(regulation) {
                this.regulation = regulation;
                await this.getArticles();
            },
            async changeTag(id) {
                const {data} = await this.API.article.articleList({
                    page: this.page,
                    regulation: this.regulation,
                    label: id,
                });
                this.total = data.total;
                this.articles = data.list;
                this.page = data.pageNum;
                this.hasPreviousPage = data.hasPreviousPage;
                this.hasNextPage = data.hasNextPage;
            },
            writeArticle() {
                if(this.userInfo){

                    this.$router.push(`/writeArticle`);
                }else{

                   this.$message.error('请先登录后再发表文章')
                }
            },
            articleNav(id) {
                this.$router.push(`/article/${id}`);
            },
        },
        mounted: async function () {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'))

            this.getRecommand();
            this.getTopArticles()
            await this.getTags({
                page: 1,
            });
            this.getArticles();

        },
    };
</script>

<style lang="scss" scoped>
  ::v-deep .el-icon-devops {
    background: url('../../assets/img/write.png') center no-repeat; // 注意此处的url，在这里引入自己的图片
    font-size: 14px;
    background-size: cover;
  }

  ::v-deep .el-icon-devops:before {
    content: "替";
    font-size: 14px;
    visibility: hidden;
  }

  .geek_container {
    // position: relative;
    width: 1170px;
    margin: 0 auto;
    margin-bottom: 150px;

    .write_button {
      width: 122px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #165DFF;
      border-radius: 4px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .search_box {
      display: inline-block;
      width: 100%;
      padding: 5px;

    }

    .content_container {
      color: #333;
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-top: 15px;

      .left {
        margin: 0 16px;
        flex: auto;
        width: 854px;

        .top-search {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
        }

        .sort {
          padding: 17px 10px;
          border-bottom: 1px dotted #e9e9e9;

          span {
            padding: 0 10px;
            border-left: 1px solid #e2e2e2;

            cursor: pointer;

            &:first-child {
              border: none;
              color: #5fb878;
              cursor: default;
            }
          }
        }

        .content {
          overflow-y: auto;
          height: 69vh;

          .article {
            width: 100%;
            padding: 10px;
            height: 196px;
            background: #FFFFFF;
            border-radius: 4px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            margin-bottom: 16px;

            .line-top {
              display: flex;
              align-items: center;

              img {
                width: 28px;
                height: 28px;
                border-radius: 50%;
                margin-right: 10px;
              }

              .name {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #333333;
                letter-spacing: 0;
              }

              .time {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 12px;
                color: #666666;
                letter-spacing: 0;
                line-height: 16px;
              }

              .line {
                width: 1px;
                height: 12px;
                background: #E1E1E1;
                margin: 0 10px;
              }
            }

            .avatar {
              display: inline-block;
              width: 45px;
            }

            .article-banner {
              display: inline-block;
              margin-left: 10px;
              margin-top: 24px;
              flex: auto;

              .text-box {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                overflow: hidden;
                -webkit-box-orient: vertical;
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
                letter-spacing: 0;
              }

              .btm-btn {
                display: flex;
                align-items: center;
                margin-top: 12px;

                .item {
                  margin: 0 12px;

                  font-family: PingFangSC-Regular;
                  font-weight: 400;
                  font-size: 12px;
                  color: #999999;
                  letter-spacing: 0;
                  line-height: 16px;

                  img {
                    margin-right: 8px;
                  }
                }
              }

              .title {
                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 20px;
                color: #333333;
                letter-spacing: 0;
              }
            }
          }
        }

        .page {
          padding: 17px 10px;
          display: flex;
          align-items: center;

          .page_button {
            padding: 5px 10px;
            cursor: pointer;
            border: 1px solid #e2e2e2;
          }

          .page_input {
            border: 1px solid #e2e2e2;
            color: #d2d2d2 !important;
            padding: 5px 5px;

            input {
              width: 24px;
              color: black;
              background-color: transparent;
              text-align: center;
              border: none;
              outline: 0;
            }
          }
        }
      }

      .right {
        width: 300px;

        .right_box {
          width: 100%;
          background-color: #fff;
          margin-bottom: 10px;

          .header-bq {
            background: url("../../assets/img/bq-bg.png");
            background-size: 100% 100%;
          }

          .header-tjwz {
            background: url("../../assets/img/tjwz-bg.png");
            background-size: 100% 100%;
          }

          .header-rmwz {
            background: url("../../assets/img/rmwz-bg.png");
            background-size: 100% 100%;
          }

          .header {
            padding: 14px 20px;
            border-bottom: 1px dotted #e9e9e9;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 20px;
            color: #333333;
            letter-spacing: 0;
          }

          .article-box {
            padding: 20px;

            .No {
              width: 18px;
              height: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 12px;
            }
            .title {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              overflow: hidden;
              -webkit-box-orient: vertical;
              font-family: PingFangSC-Medium;
              font-weight: 600;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
            }

            .btm-line {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 12px;

              .item {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                letter-spacing: 0;
                line-height: 16px;

                img {
                  width: 12px;
                  margin-right: 8px;
                }
              }
            }
          }

          .tags {
            padding: 25px 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

          }
        }
      }
    }
  }

  .tag {
    color: white;
    padding: 3px 5px;
    border-radius: 2px;
    cursor: pointer;
    white-space: nowrap;
  }

  .disable {
    pointer-events: none;
    color: #d2d2d2;
    cursor: not-allowed !important;
  }
</style>
