<template>
  <div id="app"  style="height: 100%;width: 100%; ">
    <div v-if="this.$route.name !== 'Login'&&this.$route.name !== 'forget'&&this.$route.name !== 'loginNoPsw'" style="height: 100%;width: 100%; ">
      <pub-header></pub-header>
      <div  :class="isScroll?'scroll-h':'scroll-d'">

        <keep-alive>
          <router-view v-if="keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!keepAlive"></router-view>
      </div>
    </div>

    <router-view v-if="this.$route.name == 'Login'"></router-view>
    <router-view v-if="this.$route.name == 'forget'"></router-view>
    <router-view v-if="this.$route.name == 'loginNoPsw'"></router-view>
  </div>
</template>
<script>
    import { mapState } from "vuex";
    import PubHeader from "./components/pubHeader";
    export default {
        name: "App",
        components: {PubHeader},
        computed: {
            keepAlive() {
                return this.$route && this.$route.meta && this.$route.meta.keepAlive;
            },
            isScroll() {
                return this.$route && this.$route.meta && this.$route.meta.isScroll;
            },
            ...mapState({
                backgroundColor: (state) => state.backgroundColor,
                textColor: (state) => state.textColor,
            }),
        },
        mounted() {},

    };
</script>
<style lang="scss">
.scroll-h{
  height: 100%;
  overflow-y: scroll;
}

.scroll-h::-webkit-scrollbar {
  width: 0 !important; //隐藏纵向滚动条
}
.scroll-d {
  height: 100%;
  overflow: hidden;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
@import "~@assets/reset.css";

</style>
