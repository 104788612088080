import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./utils/api";
import vueWechatTitle from "vue-wechat-title";
import tools from './utils/tools.js'
Vue.use(tools)

Vue.config.productionTip = false;
Vue.use(api).use(vueWechatTitle);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
