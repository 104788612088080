<template>
  <div class="home">
    <div class="left">
      <div class="topic topic-top"><img src="../assets/tongzhi.png" alt="">

      </div>
      <div class="topic">
        <div class="title">最新文章</div>
        <div class="article" v-for="(item,index) in articleNew" :key="index">
          <div class="title-sub">
            <div class="user">
              <img :src="item.anonymousSwitch==1?userIcon:item.icon" alt="">
              <div>
                <div class="user-name">{{item.anonymousSwitch==1?'匿名用户':item.nickname}}</div>
                <div class="user-dtl"><span>{{item.createTimeFor}}</span></div>
              </div>
            </div>
            <div class="detail" @click="articleNav(item.id)">查看详情 <i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="article-dtl">
            <div class="title"> {{item.articleTitle}}</div>
            <div class="toast">{{item.anonymousSwitch==1?'匿名用户':item.nickname}}发布于{{item.createTime}}</div>
          </div>
        </div>
      </div>

      <div class="topic">
        <div class="title">最多评论</div>
        <div class="article" v-for="(item,index) in articlePeople" :key="index">
          <div class="title-sub">
            <div class="user">
              <img :src="item.anonymousSwitch==1?userIcon:item.icon" alt="">
              <div>
                <div class="user-name">{{item.anonymousSwitch==1?'匿名用户':item.nickname}}</div>
                <div class="user-dtl"><span>{{item.createTime}}</span></div>
              </div>
            </div>
            <div class="detail" @click="articleNav(item.id)">查看详情 <i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="article-dtl">
            <div class="title"> {{item.articleTitle}}</div>
            <div class="toast">评论 {{item.commentCount}}</div>
          </div>
        </div>
      </div>

      <!--      <div class="topic" style="padding-bottom: 50px;">-->
      <!--        <div class="title-img">热门图片</div>-->
      <!--      </div>-->
    </div>
    <div class="right">

      <div class="user-list">
        <div class="con">
          <div class="now-data-myself">
            <div class="now-data-myself-time">{{ date }}</div>
            <div class="now-data-myself-week">{{ week }}</div>
          </div>
          <Calendar
            v-on:choseDay="clickDay"
            v-on:changeMonth="changeDate"
            v-on:isToday="clickToday"
          ></Calendar>
        </div>
      </div>

      <div class="title-hydr">活跃达人</div>
      <div class="user-list">
        <div class="list-item" v-for="(item,index) in userList" :key="index">
          <div style="display: flex; align-items: center; width: 270px;">
            <div :class="'No'">
              <img :src="homeList[index]" alt="">
            </div>
            <div class="user" >
              <img :src="item.icon" alt="">
              <div class="user-name">{{item.name}}</div>
            </div>
          </div>
          <div class="user-dtl" style="
                width: 80px; text-align: right">活跃度{{item.score}}</div>
        </div>
      </div>
      <div class="title-rmwz">热门文章</div>
      <div class="user-list">
        <div class="article-item" @click="articleNav(item.id)" v-for="(item,index) in articleHot" :key="index">
          <div :class="'No'">
            <img v-if="index<=4" :src="homeList[index]" alt="">
            <div style="font-size: 20px; color:#165DFF;margin-left: 8px" v-if="index>4">{{index}}.</div>
          </div>
          <div style="width: 100%;margin-left: 8px;">
            <div class="title-art" :title="item.articleTitle">{{item.articleTitle}}</div>
            <div class="user-other">
              <div class="name">{{item.anonymousSwitch==1?'匿名用户':item.nickname}}</div>
              <div class="name">
                <div class="item"><i class="el-icon-view"></i>{{item.browseCount}}</div>
                <div class="item"><i class="el-icon-thumb"></i>{{item.likeCount}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    // @ is an alias to /src

    import Calendar from '../components/calendar/index';
    import home1 from '../assets/home1.png'
    import home2 from '../assets/home2.png'
    import home3 from '../assets/home3.png'
    import home4 from '../assets/home4.png'
    import home5 from '../assets/home5.png'
    import userIcon from '../assets/user.jpg'

    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";

    export default {
        name: "Home",
        components: {Calendar},
        data() {
            return {
                userIcon,
                homeList: [
                    home1,
                    home2,
                    home3,
                    home4,
                    home5,
                ],
                articleNew: [],
                articleHot: [],
                articlePeople: [],
                userList: [],
                date: "",
                week: ""
            }
        },
        mounted() {
            var now = new Date();
            this.date = now.getDate();//得到日期
            var day = now.getDay();//得到周几
            var arr_week = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
            this.week = arr_week[day];
            this.init()
            this.getNew()
            this.getHot()
            this.getNewByPeople()
        },
        methods: {

            articleNav(id) {
                this.$router.push(`/article/${id}`);

            },
            showtime(time, forTime) {
                let date =
                    typeof time === "number"
                        ? new Date(time)
                        : new Date((time || "").replace(/-/g, "/"));
                let diff = (new Date().getTime() - date.getTime()) / 1000;
                let dayDiff = Math.floor(diff / 86400);

                let isValidDate =
                    Object.prototype.toString.call(date) === "[object Date]" &&
                    !isNaN(date.getTime());

                if (!isValidDate) {
                    window.console.error("不是有效日期格式");
                }
                const formatDate = function (date) {
                    let today = new Date(date);
                    let year = today.getFullYear();
                    let month = ("0" + (today.getMonth() + 1)).slice(-2);
                    let day = ("0" + today.getDate()).slice(-2);
                    let hour = today.getHours();
                    let minute = today.getMinutes();
                    let second = today.getSeconds();
                    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
                };

                if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
                    return formatDate(date);
                }
                return (
                    (dayDiff === 0 &&
                        ((diff < 60 && "刚刚") ||
                            (diff < 120 && "1分钟前") ||
                            (diff < 3600 && Math.floor(diff / 60) + "分钟前") ||
                            (diff < 7200 && "1小时前") ||
                            (diff < 86400 && Math.floor(diff / 3600) + "小时前"))) ||
                    (dayDiff === 1 && "昨天") ||
                    (dayDiff < 7 && dayDiff + "天前") ||
                    (dayDiff < 31 && forTime)
                );
            },
            async getHot() {
                let obj = {
                    isRecommend: 1,
                    regulation:3,
                    page: 1,
                }
                let res = await this.API.home.articleList(obj)
                if (res.code == '0000') {
                    this.articleHot = res.data.list.splice(0, 5)
                }
            },
            async getNew() {
                let obj = {
                    regulation: 0,
                    page: 1,
                }
                let res = await this.API.home.articleList(obj)
                if (res.code == '0000') {
                    this.articleNew = res.data.list.splice(0, 5)
                    this.articleNew.forEach(ele => {
                        ele.createTimeFor = this.showtime(new Date(ele.createTime).getTime(), ele.createTime)
                    })
                }
            },
            async getNewByPeople() {
                let obj = {
                    regulation: 2,
                    page: 1,
                }
                let res = await this.API.home.articleList(obj)
                if (res.code == '0000') {
                    this.articlePeople = res.data.list.splice(0, 5)
                    this.articlePeople.forEach(ele => {
                        ele.createTime = this.showtime(new Date(ele.createTime).getTime(), ele.createTime)
                    })
                }
            },
            async init() {
                let res = await this.API.home.activeTalent()
                if (res.code == '0000') {
                    this.userList = res.data
                }
            },
            clickDay(data) {
                console.log(data); //选中某天
            },
            changeDate(data) {
                console.log(data); //左右点击切换月份
            },
            clickToday(data) {
                console.log(data); // 跳到了本月
            }
        },
    };
</script>
<style scoped lang="scss">
  .home {
    width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 150px;

    .left {
      width: 66%;
      padding: 7.5px;

      .topic {
        width: 100%;
        background: transparent;
        padding: 14px 14px;
        margin-bottom: 18px;

        .title-img {
          padding-bottom: 12px;
          border-bottom: 1px solid #eee;
        }

        .title {
          font-family: PingFangSC-Medium;
          font-weight: 500;
          font-size: 24px;
          color: #333333;
          letter-spacing: 0;
          line-height: 32px;
        }

        .article {
          margin-top: 12px;
          padding: 20px;
          background: #FFFFFF;
          border-radius: 4px;

          .title-sub {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .detail {
              cursor: pointer;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 12px;
              color: #165DFF;
              letter-spacing: 0;
              display: flex;
              align-items: center;

              i {
                color: #165DFF;
              }
            }

            .user {
              display: flex;

              img {
                width: 45px;
                height: 45px;
                margin-right: 12px;
                border-radius: 50%;
              }

              .user-name {

                font-family: PingFangSC-Medium;
                font-weight: 500;
                font-size: 16px;
                color: #165DFF;
                letter-spacing: 0;
                margin-bottom: 6px;
              }

              .user-dtl {
                span {

                  font-family: PingFangSC-Regular;
                  font-weight: 400;
                  font-size: 12px;
                  color: #666666;
                  letter-spacing: 0;
                  line-height: 16px;
                  margin-right: 12px;
                }
              }
            }
          }

          .article-dtl {
            margin: 24px 0 20px 0;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;

            .toast {
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              letter-spacing: 0;
              line-height: 16px;
            }

            .title {

              display: -webkit-box;
              -webkit-line-clamp: 1;
              overflow: hidden;
              -webkit-box-orient: vertical;
              margin: 24px 0 20px 0;
              font-family: PingFangSC-Medium;
              font-weight: 600;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
            }
          }
        }

      }

      .topic-top {
        background: #EAF2FF;
        border-radius: 4px;
        display: flex;
        align-items: center;
        position: relative;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        letter-spacing: 0;

        img {
          margin-right: 8px;
        }

        .detail {

        }
      }
    }

    .right {
      padding: 7.5px;
      width: 33%;

      .title-rmwz {
        background: url("../assets/rmwz-bg.png");
        background-size: 100% 100%;
        width: 100%;
        height: 56px;
        font-family: PingFangSC-Medium;
        font-weight: 600;
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        padding: 20px 14px;
      }

      .title-hydr {
        background: url("../assets/hydr-bg.png");
        background-size: 100% 100%;
        width: 100%;
        height: 56px;
        font-family: PingFangSC-Medium;
        font-weight: 600;
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        padding: 20px 14px;
      }

      .user-list {
        width: 100%;
        background: white;
        padding: 14px 14px;
        margin-bottom: 18px;


        .article-item {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 18px;
          align-items: flex-start;

          .title-art {
            cursor: pointer;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
            -webkit-box-orient: vertical;

          }

          .user-other {
            display: flex;
            justify-content: space-between;
            margin-top: 12px;

            .name {
              font-family: PingFangSC-Regular;
              font-weight: 400;
              font-size: 12px;
              color: #999999;
              letter-spacing: 0;
              line-height: 16px;
              display: flex;

              .item {
                font-family: PingFangSC-Regular;
                font-weight: 400;
                font-size: 12px;
                color: #999999;
                letter-spacing: 0;
                line-height: 16px;
                margin: 0 8px;
                width: 40px;

                i {
                  color: #999999;
                }
              }
            }
          }

          .reader {
            color: gray;
          }
        }

        .list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 24px;
          background: white;

          .No {
            width: 18px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
          }


          .user {
            display: flex;
            align-items: center;

            img {
              width: 45px;
              height: 45px;
              border-radius: 50px;
              margin-right: 12px;
              margin-left: 12px;
            }

            .user-name {
              font-family: PingFangSC-Medium;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              width: 70%;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              overflow: hidden;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }

</style>
<style>

  .now-data-myself {
    width: 40%;
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid rgba(227, 227, 227, 0.6);
  }

  .con {
    position: relative;
    margin: 0 auto;
  }

  .con .wh_content_all {
    background: transparent !important;
  }

  .wh_top_changge li {
    color: #165DFF !important;
    font-size: 15px !important;
  }

  .wh_content_item, .wh_content_item_tag {
    color: #303133 !important;
  }

  .wh_content_item .wh_isToday {
    background: #165DFF !important;
    color: #fff !important;
  }

  .wh_content_item .wh_chose_day {
    background: #165DFF !important;
    color: #ffff !important;
  }

  .wh_item_date:hover {
    background: rgb(217, 236, 255) !important;
    border-radius: 100px !important;
    color: rgb(102, 177, 255) !important;
  }

  .wh_jiantou1 {
    border-top: 2px solid #909399 !important;
    border-left: 2px solid #909399 !important;
    width: 7px;
    height: 7px;
  }

  .wh_jiantou2 {
    border-top: 2px solid #909399 !important;
    border-right: 2px solid #909399 !important;
    width: 7px;
    height: 7px;
  }

  .wh_top_tag {
    color: #165DFF;
    border-top: 1px solid rgba(227, 227, 227, 0.6);
    border-bottom: 1px solid rgba(227, 227, 227, 0.6);
  }

  .wh_container {
    max-width: 280px;
  }

  .wh_top_changge {
    display: flex;
    width: 50%;
    margin-left: 43%;
  }

  .now-data-myself-time {
    color: #165DFF;
    font-size: 28px;
    height: 30px;
    font-family: "Helvetica Neue";
  }

  .now-data-myself-week {
    font-size: 10px;
    color: #909399;
  }

  .wh_top_changge .wh_content_li {
    font-family: Helvetica;
  }

</style>
